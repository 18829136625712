import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { AngualertService } from 'angualert';
import * as M from 'materialize-css';

import { uuidv4 } from '../utils/utils';
import { User } from '../admin/user';
import { FolderData } from './folder-data';
import { FolderService } from './folder.service';
import { PicService } from '../pic/pic.service';
import { PicData } from '../pic/pic-data';
import { Theme } from '../admin/theme/themes';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ThemeService } from '../admin/theme/theme.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class FolderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() key: string;                   // The key to use to select the current folder from the map
  @Input() currentRoute: string;          // The current route, used to see if this folder is currently selected
  @Output() childFolderOpened = new EventEmitter<boolean>();
  @ViewChild('addFolderInput') addFolderInput: ElementRef;
  @ViewChild('renameFolderInput') renameFolderInput: ElementRef;
  folder: FolderData;
  subFolders: string[];
  folderName: string = '';
  isRenaming: boolean = false;
  isAdding: boolean = false;
  isDragover: boolean = false;
  user: User = null;
  id: string = 'folder-context-' + uuidv4();
  currentTheme: Theme = null;
  private dropdownInstance: any;
  private foldersSub: Subscription;
  private themeSub: Subscription;

  constructor(private router: Router,
    private folderService: FolderService,
    private picService: PicService,
    private angualertService: AngualertService,
    themeService: ThemeService) {
    this.themeSub = themeService.theme$.subscribe(theme => this.currentTheme = theme);
  }

  ngOnInit() {
    this.foldersSub = this.folderService.folderDataMap$.subscribe(folderDataMap => {
      if (folderDataMap == null) return;

      this.folder = folderDataMap[this.key];
      if (this.folder == null) {
        return;
      }

      this.subFolders = this.getSubFolders(this.folder);

      // If this folder was matched in a filtering search, make sure it is opened
      if (this.folder.isMarkedForceOpen) {
        this.folder.isOpened = true;
        this.folder.isMarkedForceOpen = false;  // Clear this out
      } else {
        let r = new RegExp(`^${this.folder.url}\/+|^${this.folder.url}$`, 'i');
        this.folder.isOpened = this.folder.id === '0' || r.test(this.currentRoute);
      }
    });
  }

  ngAfterViewInit(): void {
    var element = document.querySelector('#' + this.id);
    this.dropdownInstance = M.Dropdown.init(element, {
      inDuration: 250,
      outDuration: 250,
      constrainWidth: false,      // Does not change width of dropdown to that of the activator
      gutter: 10,                 // Spacing from edge
      coverTrigger: false,
      belowOrigin: true,          // Displays dropdown below the button
      alignment: 'right',         // Displays dropdown with edge aligned to the left of button
      stopPropagation: true       // Stops event propagation
    });
  }

  ngOnDestroy(): void {
    if (this.dropdownInstance != null)
      this.dropdownInstance.destroy();
    if (this.foldersSub != null)
      this.foldersSub.unsubscribe();
    this.themeSub.unsubscribe();
  }

  handleLoginChange(user: User) {
    this.user = user;
  }
  isLoggedIn() {
    return this.user != null;
  }

  /**
   * Gets a sorted list of all subfolders.
   */
  private getSubFolders(folder: FolderData): string[] {
    if (folder == null) return [];

    return folder.subFolderUrls.sort((a, b) => {
      return a < b ? -1 : (a > b) ? 1 : 0;
    });
  }

  private timer: any;
  private delay: number = 200;
  private prevent: boolean = false;
  /**
   * Changes the currently selected folde
   */
  selectFolder() {
    event.stopImmediatePropagation();
    if (this.isRenaming) {
      return false;
    }

    // Wait a bit to check for double click
    this.timer = setTimeout(() => {
      if (!this.prevent) {
        // Triggers a subscription in core.component
        this.router.navigateByUrl(this.folder.url, { state: this.folder });
      }
      this.prevent = false;
    }, this.delay);

    return false;
  }

  /**
   * Opens or closes the folder
   */
  toggleFolder() {
    if (this.isRenaming) return false;

    clearTimeout(this.timer);
    this.prevent = true;
    this.folder.isOpened = !this.folder.isOpened;
    this.childFolderOpened.emit(this.folder.isOpened);
  }
  /**
   * When a child folder opens, we need to open this one if closed.
   * @param isFolderOpened
   */
  onChildFolderOpened(isChildFolderOpened) {
    if (!this.folder.isOpened && isChildFolderOpened) {
      this.folder.isOpened = true;
    }
  }

  startRenameFolder() {
    if (!this.isLoggedIn()) return;

    this.isRenaming = !this.isRenaming;
    this.folderName = this.folder.name;
    setTimeout(() => {
      this.renameFolderInput.nativeElement.focus();
      this.renameFolderInput.nativeElement.select();
    }, 100);
  }
  renameFolder() {
    if (!this.isLoggedIn()) return;
    if (this.folderName === '') return;

    this.isRenaming = !this.isRenaming;

    this.folderService.renameFolder(this.folderName, this.folder)
      .subscribe(() => {
        // Can leave this empty, since this folder component will be destroyed if the rename is successful
      }, error => this.angualertService.warn(error.message));
  }
  closeRename() {
    this.isRenaming = false;
  }

  startAddFolder() {
    if (!this.isLoggedIn()) return;
    this.isAdding = true;
    this.folderName = '';

    setTimeout(() => {
      this.addFolderInput.nativeElement.focus();
      this.addFolderInput.nativeElement.select();
    }, 100);
  }
  addFolder() {
    if (this.folderName === '') return;
    this.isAdding = false;

    let url, parentUrl, prefix;
    if (this.folder.id === '0') {
      url = `/${this.folderName}`;
      parentUrl = '/';
      prefix = `${this.folderName}/`;
    } else {
      parentUrl = this.folder.url;
      url = `${parentUrl}/${this.folderName}`;
      prefix = `${this.folder.prefix}${this.folderName}/`;
    }

    let newFolder = new FolderData({
      url,
      parentUrl,
      prefix,
      name: this.folderName,
      isOpened: true
    });

    this.folderService.addFolder(newFolder)
      .subscribe(added => {
        if (added) {
          this.angualertService.info(`Added the folder '${this.folderName}'!`);
          this.folder.isOpened = true; // Open the folder to show the new one
        } else {
          this.angualertService.warn(`Could not add the folder '${this.folderName}'.`);
        }
      }, error => {
        this.angualertService.error(error.message);
      });
  }
  closeAdd() {
    this.isAdding = false;
  }

  startDeleteFolder() {
    if (!this.isLoggedIn() || this.folder.id === '0') return;
    let folder = this.folder;
    this.angualertService.warn(`Are you sure you want to delete the folder '${folder.name}'?`, {
      alertConfirmed: (confirmed) => {
        if (confirmed) {
          this.deleteFolder(folder);
        }
      }
    });
  }
  deleteFolder(folder: FolderData) {
    if (!this.isLoggedIn() || this.folder.id === '0') return;

    this.folderService.deleteFolder(folder)
      .subscribe(deleted => {
        if (deleted) {
          this.angualertService.info(`Deleted the folder '${folder.name}'.`);
        } else {
          this.angualertService.warn(`The folder '${folder.name}' could not be deleted.`);
        }
      }, (error) => {
        this.angualertService.error(error.message)
      });
  }

  isDragEnabled() {
    if (!this.isLoggedIn()) return false;
    return this.folder.id !== '0' && this.isLoggedIn();
  }
  itemDropped(data: any) {
    if (!this.isLoggedIn()) return;
    let { dragData } = data;
    let folder = this.folder;
    // TODO This check scares me... Should probably figure a better way of determining if it is pic or folder
    if (this.isDropDataPic(dragData)) {
      let picData = dragData as PicData;
      this.picService.changeFolder(picData, folder)
        .subscribe(() => {
          this.folderService.picFolderChange(picData, folder);
          this.angualertService.info(`Moved '${picData.fullName}' to the folder '${folder.name}'.`);
        }, error => {
          if (error.statusCode === 409) {
            this.angualertService.warn(error.message)
          } else {
            this.angualertService.error(error.message)
          }
        });
    } else {
      let folderData = dragData as FolderData;
      this.folderService.moveFolder(folderData, folder)
        .subscribe(() => {
          this.angualertService.info(`Moved the folder '${folderData.name}' to '${folder.name}'.`);
        }, error => this.angualertService.error(error.message));
    }

    this.isDragover = false;
  }

  onDragStart() {
    if (!this.isLoggedIn()) return;
    this.folder.isOpened = false;  // Don't show contents to prevent dropping a parent into its child
  }
  onDragOver() {
    if (!this.isLoggedIn()) return;
    this.isDragover = true;
  }
  onDragLeave(data: any) {
    if (!this.isLoggedIn()) return;
    // If the element that we are leaving has the same id as the element we are coming from, then we are still in the folder zone
    let { fromElement, toElement } = data.mouseEvent;
    if (fromElement == null || toElement == null) return;
    let fromId = this.getId(fromElement.id);
    let toId = this.getId(toElement.id);
    this.isDragover = fromId === toId && fromElement.id !== 'main' && fromElement.id !== 'folderContainer';
  }

  allowDrop() {
    if (!this.isLoggedIn()) return false;
    let folderId = this.folder.id;
    // Don't allow dropping if the container is the same folder
    return (data: any) => {
      if (data == null) return false;
      return folderId !== data.id && folderId !== data.parentId;
    }
  }

  private getId(id): string {
    let matches = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/.exec(id);
    if (matches) return matches[0];
    return null;
  }
  private isDropDataPic(data: any) {
    return data.extension != null;
  }

  refreshFolders() {
    this.folderService.loadFolders().subscribe(
      () => { },
      error => {
        this.angualertService.error(error.message);
      });
  }

  onRightClick() {
    if (!this.isLoggedIn()) return true;

    // Close any open
    const otherDropdownElements = document.querySelectorAll('.dropdown-button');

    otherDropdownElements.forEach(elem => {
      const other = M.Dropdown.getInstance(elem);
      if (other === this.dropdownInstance && this.dropdownInstance.isOpen) {
        this.dropdownInstance.recalculateDimensions();
      }
      if (other != null && other.isOpen || other !== this.dropdownInstance) {
        other.close();
      }
    });

    this.dropdownInstance.open();
    return false;
  }

  buttonClass() {
    return this.currentRoute != null && this.currentRoute === this.key ? 'light-blue lighten-4' : '';
  }
  spacingStyle(isPadding: boolean = false) {
    let folder = this.folder;
    let space = folder.id === '0' ? 0.5 : folder.prefix.split('/').length;
    let styleKey = isPadding ? 'padding-left' : 'margin-left';
    let style = {};
    style[styleKey] = `${space}em`
    return style;
  }
}
