import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  template: `
  <ng-container *ngIf="isLoading">
    <div class="progress mt-2">
      <div class="indeterminate"></div>
    </div>
  </ng-container>
  `
})
/**
 * Component for showing a progress bar.
 * <progress-bar [isLoading]="isPicLoading"></progress-bar>
 */
export class ProgressBar {
  @Input() public isLoading: boolean = false;
}
