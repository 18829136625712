import { Component, OnInit, OnDestroy } from '@angular/core';
import { PicData } from '../pic/pic-data';
import { PicService } from '../pic/pic.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';


@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  pics: PicData[];
  isSearchingPics = true;
  query: string;
  private picsSub: Subscription;
  private isSearchingPicsSub: Subscription;
  private querySub: Subscription;

  constructor(private picService: PicService,
    private route: ActivatedRoute) {
    this.querySub = route.params.pipe(map(p => p.query)).subscribe(query => this.query = query);
  }

  ngOnInit() {
    this.picsSub = this.picService.searchedPics$.subscribe(pics => {
      this.pics = pics;
    });
    this.isSearchingPicsSub = this.picService.isSearchingPics$.subscribe(isSearchingPics => this.isSearchingPics = isSearchingPics);
  }

  ngOnDestroy() {
    this.picsSub.unsubscribe();
    this.isSearchingPicsSub.unsubscribe();
    this.querySub.unsubscribe();
  }
}
