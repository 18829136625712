import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';

import * as M from 'materialize-css';
import { FolderDataMap } from '../folder/folder-data';
import { Router, NavigationEnd } from '@angular/router';
import { FolderService } from '../folder/folder.service';
import { AngualertService } from 'angualert';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements AfterViewInit, OnInit, OnDestroy {
  folderMap: FolderDataMap;          // The source of truth for all folders
  routePath: string;                 // The current url path, used for getting folders
  folderSearchText = '';
  folderSearchControl = new FormControl();
  isLoadingFolders: boolean = false;  // Are we loading folders from the server?
  baseFolderUrl = '/';
  private sideNavInstance: any;
  private routerSub: Subscription;
  private foldersSub: Subscription;
  private areFoldersLoadingSub: Subscription

  constructor(private router: Router,
    private folderService: FolderService,
    private angualertService: AngualertService) {
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routePath = decodeURI(event.url);  // Need to decode for things like spaces
      }
    });
    this.areFoldersLoadingSub = this.folderService.areFoldersLoading$.subscribe(loading => this.isLoadingFolders = loading);
  }

  ngAfterViewInit() {
    const element = document.querySelector('.sidenav');
    this.sideNavInstance = M.Sidenav.init(element, {});
  }

  ngOnInit() {
    this.folderService.loadFolders().subscribe(
      () => { },
      error => {
        this.angualertService.error(error.message);
      });

    this.foldersSub = this.folderService.folderDataMap$.subscribe(folderDataMap => {
      this.folderMap = folderDataMap;
    }, (error) => {
      this.isLoadingFolders = false;
      this.angualertService.error(error.message);
    });

    this.folderSearchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(folderSearchText => this.folderService.filterFolders(folderSearchText));
  }

  ngOnDestroy() {
    if (this.sideNavInstance != null) {
      this.sideNavInstance.destroy();
    }
    this.foldersSub.unsubscribe();
    this.routerSub.unsubscribe();
    this.areFoldersLoadingSub.unsubscribe();
  }
}
