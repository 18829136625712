import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { UserService } from '../admin/user.service';
import Logger from '../utils/logger';

export class BaseService {

  constructor(private serviceName: string,
    private _httpClient: HttpClient,
    private _loginService: UserService) { }

  get<T>(url: string, ignoreAuth: boolean = false): Observable<T> {
    return this.buildRequestOptions(null, ignoreAuth)
      .pipe(
        flatMap(headers => {
          if (headers == null) {
            return this._httpClient.get<T>(url);
          }
          const httpOptions = { headers };
          return this._httpClient.get<T>(url, httpOptions);
        })
      );
  }

  post<T>(url: string, body: any, headers: HttpHeaders = null): Observable<T> {
    return this.buildRequestOptions(headers)
      .pipe(
        flatMap(headers => {
          const httpOptions = { headers };
          return this._httpClient.post<T>(url, body, httpOptions);
        })
      );
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.buildRequestOptions()
      .pipe(
        flatMap(headers => {
          const httpOptions = { headers };
          return this._httpClient.put<T>(url, body, httpOptions);
        })
      );
  }

  delete<T>(url: string): Observable<T> {
    return this.buildRequestOptions()
      .pipe(
        flatMap(headers => {
          const httpOptions = { headers };
          return this._httpClient.delete<T>(url, httpOptions);
        })
      );
  }

  private buildRequestOptions(headers: HttpHeaders = null, ignoreAuth: boolean = false): Observable<HttpHeaders> {
    if (ignoreAuth) return of(null);

    return this._loginService.getIdToken()
      .pipe(
        map(token => {
          let fullHeaders;
          if (headers == null) {
            // Set default content-type
            fullHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json');
          } else {
            fullHeaders = headers;
          }
          return fullHeaders
            .set('Authorization', 'Bearer ' + token);
        })
      );
  }

  handleError(error: HttpErrorResponse) {
    const genericMessage = 'Something went wrong with your request, please try again.';
    let message;
    if (error.error instanceof ErrorEvent) {
      message = 'There was a problem with your request, please try again.';
      Logger.error(`Error in ${this.serviceName}: ${message} - ${error.status} - ${error.statusText} - ${error.error}`);
    } if (error instanceof Error) {
      let e = error as Error;
      message = genericMessage;
      Logger.error(e);
    } else {
      if (error.status === 401) {
        message = 'Sorry, you\'re not allowed to do that.';
      } else if (error.status === 404) {
        message = 'Could not find anything at your requested route, please try again.';
      } else {
        if (error.error != null) {
          if (error.error.message != null) {
            message = error.error.message;
          } else {
            message = error.error
          }
        } else {
          message = genericMessage;
        }
      }
      Logger.error(`Error in ${this.serviceName}: ${message} - ${error.status}`, error);

      if (!message) {
        // Something went wrong, maybe the error body could not be converted correctly
        message = genericMessage;
      }
    }

    return throwError({
      message: message,
      statusCode: error.status || 500
    });
  }
}
