import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AngualertService } from 'angualert';
import { UserService } from '../user.service';
import { LoginCodes } from '../login-result';
import { User } from '../user';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styles: [`
    .container {
      max-width: 600px;
      margin: 0 auto;
    }
  `]
})
export class LoginComponent {
  public model: User = new User();
  public needsPasswordUpdate: boolean = false;
  public needsPasswordReset: boolean = false;
  public loading: boolean = false;
  public barLabel: string = 'Password strength:';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  private adminUrl: string = '/admin';

  constructor(private router: Router,
    private titleService: Title,
    private userService: UserService,
    private angualertService: AngualertService) {
    titleService.setTitle(`${environment.appName} | Login`);

    userService.isLoggedIn()
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigate([this.adminUrl]);
        }
      })
  }

  public loginAction() {
    if (this.needsPasswordReset) {
      return this.resetPassword();
    }
    this.login()
  }

  public login() {
    if (this.validate()) {
      this.loading = true;
      this.userService.authenticate(this.model, this.needsPasswordUpdate)
        .subscribe(result => {
          this.loading = false;
          switch (result.code) {
            case LoginCodes.SUCCESS:
              this.router.navigate([this.adminUrl]);
              break;
            case LoginCodes.PASSWORD_UPDATE:
              this.angualertService.warn(result.message);
              this.needsPasswordUpdate = true;
              this.model.newPassword = '';
              this.model.confirmPassword = '';
              break;
            case LoginCodes.PASSWORD_RESET:
              this.angualertService.warn(result.message);
              this.needsPasswordReset = true;
              break;
            case LoginCodes.ERROR:
            default:
              this.angualertService.warn('Could not authenticate, please try again.');
              break;
          }
        }, errorResult => {
          this.loading = false;
          this.angualertService.error(errorResult.message);
        });
    }
  }

  public updatePassword() {
    if (this.validate()) {
      this.loading = true;
      this.userService.updatePassword(this.model)
        .subscribe(updated => {
          this.loading = false;
          if (updated.code === LoginCodes.SUCCESS) {
            this.router.navigate([this.adminUrl]);
          } else {
            this.angualertService.warn('Could not update your password, please try again.');
          }
        }, error => {
          this.loading = false;
          this.angualertService.error(error.message);
        });
    }
  }

  public forgotPassword(forceResend: boolean) {
    this.titleService.setTitle(`${environment.appName} | Reset Password`);

    if (this.model.username == null || this.model.username === '') {
      this.angualertService.warn('You need to give a username.');
      return false;
    }

    this.loading = true;
    this.userService.forgotPassword(this.model, forceResend)
      .subscribe(result => {
        this.loading = false;
        if (result.code === LoginCodes.SUCCESS) {
          this.needsPasswordReset = true;
          if (result.message != null && result.message !== '') {
            this.angualertService.info(result.message);
          }
          return;
        }
        this.angualertService.warn('Could not make a reset password request...');
      }, error => {
        this.loading = false;
        this.angualertService.error(error.message);
      });
  }
  public resetPassword() {
    if (this.validate()) {
      this.loading = true;
      this.userService.confirmForgotPassword(this.model)
        .subscribe(result => {
          this.loading = false;
          if (result.code === LoginCodes.SUCCESS) {
            // The password has been reset, now we can login
            // We need to assign the password here for when we try to login
            this.model.password = this.model.newPassword;
            return this.login();
          } else {
            this.angualertService.warn('Could not reset your password, please try again.');
          }
        }, error => {
          this.loading = false;
          this.angualertService.error(error.message);
        });
    }
  }

  private validate(): boolean {
    if (this.model.username == null || this.model.username === '') {
      this.angualertService.warn('You need to give a username.');
      return false;
    }
    if (this.needsPasswordReset && this.model.verificationCode == null || this.model.verificationCode === '') {
      this.angualertService.warn('You need to give the verification code that was emailed to you.');
      return false;
    }
    if (this.needsPasswordReset || this.needsPasswordUpdate) {
      if (this.model.newPassword !== this.model.confirmPassword) {
        this.angualertService.warn('Your new password and the confirm password do not match.');
        return false;
      }
    }
    return true;
  }

  public isLoginForm() {
    return !this.needsPasswordReset;
  }

  public cancel() {
    this.titleService.setTitle(`${environment.appName} | Login`);

    this.needsPasswordReset = false;
    this.needsPasswordUpdate = false;
  }

  public text() {
    if (this.needsPasswordUpdate)
      return 'Update Password';
    if (this.needsPasswordReset)
      return 'Reset Password';
    return 'Log In';
  }
}
