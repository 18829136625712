import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'file-manager',
  template: '<input type="file" #fileInput id="fileInput" multiple (change)="onChange($event)" style="display:none">'
})
export class FileManager {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() filesReady: EventEmitter<File[]> = new EventEmitter;

  importLocalFiles() {
    this.fileInput.nativeElement.click();
  }

  onChange(event) {
    let files = event.currentTarget.files;
    if (files == null || files.length === 0) return;
    this.filesReady.emit(files);
    this.fileInput.nativeElement.value = null;
  }
}
