export class ThemeGroup {
  public themes: Theme[] = [];

  public add(theme: Theme) {
    this.themes.push(theme);
  }
}

export class Theme {
  public name: string;
  public bright: string;
  public text: string;

  constructor(name: string, bright: string, text: string) {
    this.name = name;
    this.bright = bright;
    this.text = text;
  }
}

const brights: string[] = [
  '',
  'lighten-1',
  'lighten-2',
  'lighten-3',
  'lighten-4',
  'lighten-5',
  'darken-1',
  'darken-2',
  'darken-3',
  'darken-4',
  'accent-1',
  'accent-2',
  'accent-3',
  'accent-4'
];
const names: string[] = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey',
  'black',
  'white',
  'transparent',
]

export const themeGroups = new Array<ThemeGroup>();
let hardThemes = new ThemeGroup();
for (let name of names) {
  if (name === 'black' || name === 'white' || name === 'transparent') {
    hardThemes.add(new Theme(name, '', name === 'black' ? 'white' : 'black'));
  } else {
    let themeGroup = new ThemeGroup();
    for (let bright of brights) {
      const text = bright.match(/lighten-[3-5]/) ? 'black' : 'white';
      let theme = new Theme(name, bright, text)
      themeGroup.add(theme);
    }
    themeGroups.push(themeGroup);
  }
}
themeGroups.push(hardThemes);

export const DefaultTheme: Theme = themeGroups[17].themes[3]; // grey ligthen-3
