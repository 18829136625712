import { Directive, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from './user.service';
import { User } from './user';

@Directive({
  selector: '[auth]',
  outputs: ['loginChange']
})
export class AuthDirective implements AfterViewInit, OnDestroy {
  @Output() loginChange: EventEmitter<User> = new EventEmitter;
  public isLoggedIn: boolean = false;
  public user: User = null;
  private subscription: Subscription;

  constructor(private userService: UserService,
    private cdr: ChangeDetectorRef) {
  }
  ngAfterViewInit() {
    this.userService.isLoggedIn()
      .subscribe(isLoggedIn => {

        this.userService.getUser()
          .subscribe(user => {
            this.loginChange.emit(user);
            this.cdr.detectChanges()
          }, error => {
            // Just catch this error, there is no user.
            // May want to emit an empty or null user here, but probably not needed
          });

        this.subscription = this.userService.user$
          .subscribe((user) => {
            this.loginChange.emit(user);
            this.cdr.detectChanges()
          });
      });
  }

  ngOnDestroy() {
    if (this.subscription != null)
      this.subscription.unsubscribe();
  }
}
