import { PicData } from '../pic/pic-data';

export class FolderData {
  id: string;                           // Random id for this folder. Changes everytime we hit the server
  parentId: string;                     // Id for this folder's parent
  url: string;                          // The url for this folder. Used in changing the route, and is also the key to find a folder in the folderMap. Is unique
  parentUrl: string;                    // The url (or key for the folder map) for this folder's parent
  prefix: string;                       // The prefix to be used in AWS for getting contents for this folder. Is unique
  name: string;                         // The name of the folder
  subFolderUrls: string[];
  folders: FolderData[];
  pics: PicData[] = [];                 // Keep a copy of the pics in memory, until the page is refreshed
  isOpened: boolean = false;
  isMarkedForceOpen: boolean = false;   // Used only in filtering folders to ensure that they remain open after route changes
  shouldLoadPics: boolean = true;       // Trigger a reload of the pics

  constructor(data: any = null) {
    if (data == null) data = {};
    this.id = data.id;
    this.parentId = data.parentId;
    this.url = data.url;
    this.parentUrl = data.parentUrl || null;
    this.prefix = data.prefix;
    this.name = data.name;
    this.folders = data.folders || [];
    this.subFolderUrls = data.subFolderUrls || [];
    this.pics = data.pics || [];
    this.isOpened = data.isOpened || false;
  }
}

export interface FolderDataMap { [folder: string]: FolderData }

export interface FolderRenameData {
  oldName: string;
  newName: string;
  oldPrefix: string;
  newPrefix: string;
  oldUrl: string;
  newUrl: string;
  parentUrl: string;
}
