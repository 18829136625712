import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import * as M from 'materialize-css';

@Component({
  selector: 'password-strength',
  template: `
  <div class="row">
    <ng2-password-strength-bar class="col s10" [passwordToCheck]="passwordToCheck" [barLabel]="barLabel" [barColors]="myColors"></ng2-password-strength-bar>

    <a class="col s2 right-align tooltipped cursor-pointer" data-position="bottom" data-delay="50" [attr.data-tooltip]="tooltip">
      <i class="material-icons">info</i>
    </a>
  </div>`
})
export class PasswordStrength implements AfterViewInit, OnDestroy {
  @Input() passwordToCheck: string;
  barLabel: string = 'Password strength:';
  myColors: string[] = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  tooltip: string = `
    <div class="left-align">
      Your password must:
      <div>- Be at least 8 characters long</div>
      <div>- Have at least one number</div>
      <div>- Have at least one uppercase letter</div>
      <div>- Have at least one lowrecase letter</div>
      <div>- Have at least one special character</div>
    </div>`;
  private tooltipInstance: any;

  ngAfterViewInit() {
    var element = document.querySelector('.tooltipped');
    this.tooltipInstance = M.Tooltip.init(element, {});
  }

  ngOnDestroy() {
    if (this.tooltipInstance != null)
      this.tooltipInstance.destroy();
  }
}
