export class PicData {
  url: string;
  name: string;
  fullName: string;
  extension: string;
  tags: Tag[] = [];
  alt: string;
  folderId: string;
  prefix: string;
  description: string;
  order: number;
  errorData: { message: string, statusCode: number };

  constructor(data?: any) {
    if (data == null) data = {};
    this.url = data.url || null;
    this.name = data.name || null;
    this.fullName = data.fullName || null;
    this.extension = data.extension || null;
    this.tags = data.tags || [];
    this.alt = data.alt || null;
    this.folderId = data.folderId || null;
    this.prefix = data.prefix || null;
    this.description = data.description || null;
    this.order = data.order || null;
  }
}

export class Tag {
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}
