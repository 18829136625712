import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin, of, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import { BaseService } from '../utils/base.service';
import { UserService } from '../admin/user.service';
import { PicData, Tag } from './pic-data';
import { FolderData } from '../folder/folder-data';
import { environment } from '../../environments/environment';
import FileData from '../utils/dragon-drop/file-data';
import Logger from '../utils/logger';
import ApiResponse from '../utils/api-response';

@Injectable({
  providedIn: 'root'
})
export class PicService extends BaseService {
  private apiUrl = `${environment.apiUrl}/pics`;
  private picsKey: string = 'PICS';

  searchedPics$ = new BehaviorSubject<PicData[]>([]);
  isSearchingPics$ = new BehaviorSubject<boolean>(true);

  constructor(httpClient: HttpClient, loginService: UserService) {
    super('Pic Service', httpClient, loginService);
  }

  /**
   * Gets all pictures for a folder.
   * @param prefix The folder prefix to use when getting pics from the images.jerrymcnealy S3 bucket
   */
  getPictures(prefix: string): Observable<PicData[]> {
    if (prefix == null || prefix === '') {
      return Observable.throw({
        statusCode: 404,
        message: 'No pics found here.'
      });
    }
    let key = `?prefix=${prefix}`;
    let url = `${this.apiUrl}${key}`;

    return super.get<ApiResponse<PicData[]>>(url, true)
      .pipe(
        map(response => {
          if (response.statusCode === 200) {
            return response.data || [];
          }
          Logger.error(`Could not get pictures. Message: ${response.message} - Status: ${response.statusCode} Error: ${response.error} - Stack: ${response.stack}`);
          throwError({
            message: response.message,
            statusCode: response.statusCode
          });
        }),
        catchError(error => super.handleError(error))
      )
  }

  /**
   * Uploads an image to the images.jerrymcnealy S3 bucket. Note that this only uploads the first image in the last at the moment.
   * @param fileList
   * @param folder
   */
  uploadPictures(fileList: FileData[], folder: FolderData): Observable<PicData[]> {
    let order;
    let last = folder.pics[folder.pics.length - 1];
    if (last == null) order = 0;
    else order = last.order + 1;

    let promises = new Array<Observable<PicData>>();
    for (let i = 0; i < fileList.length; i++) {
      let task = this.uploadPicture(fileList[i], folder, order++)
        .pipe(catchError(e => {
          const errorPicData = new PicData();
          errorPicData.errorData = e;
          return of(errorPicData);
        }));
      promises.push(task);
    }

    return forkJoin(promises);
  }

  uploadPicture(file: FileData, folder: FolderData, order: number): Observable<PicData> {
    let prefix = folder.prefix === '/' ? '' : folder.prefix;
    let fileName = file.name;
    let key = `${prefix}${fileName}`;

    return this.getFileData(file.data)
      .pipe(
        switchMap(fileData => {
          let request = {
            key,
            fileName,
            prefix,
            data: fileData,
            order,
            folderId: folder.id
          };

          return super.post<ApiResponse<PicData>>(this.apiUrl, request)
            .pipe(
              map(response => {
                // Pic with that name already exists
                if (response.statusCode === 409) {
                  const errorPicData = new PicData();
                  errorPicData.errorData = {
                    message: response.message,
                    statusCode: response.statusCode
                  };
                  return errorPicData;
                }
                // If some error happened
                if (response.statusCode !== 200) {
                  Logger.error(`Error in pic upload: ${response.message} Error: ${response.error}, Stack: ${response.stack}`);
                  const errorPicData = new PicData();
                  errorPicData.errorData = {
                    message: response.message,
                    statusCode: response.statusCode
                  };
                  return errorPicData;
                }

                const picData = response.data;
                this.addTag(new Tag('order', String(order)), picData)
                  .subscribe(() => {
                  }, error => {
                    Logger.error(error)
                  });

                return picData;
              }),
              take(1),  // Important, need to use this to complete the forkJoin!
            );
        }),
        catchError(error => {
          return super.handleError(error)
        })
      )
  }

  private getFileData(file: File | Blob): Observable<string | ArrayBuffer> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        observer.next(reader.result)
        observer.complete();
      };
      reader.onerror = error => {
        observer.error(error)
        observer.complete();
      };
    });
  }

  renamePicture(picData: PicData, newPicName: string): Observable<boolean> {
    let prefix = picData.prefix === '/' ? '' : picData.prefix;
    let oldKey = `${prefix}${picData.fullName}`;
    let newKey = `${prefix}${newPicName}${picData.extension}`;
    return this.copyPicture(oldKey, newKey, newPicName);
  }

  changeFolder(picData: PicData, folderData: FolderData): Observable<boolean> {
    // Mark this pic for changing its order tag next time we call GET on its new folder
    this.addTag(new Tag('changeOrder', 'true'), picData).subscribe(() => { });

    let oldPrefix = picData.prefix === '/' ? '' : picData.prefix;
    let oldKey = `${oldPrefix}${picData.fullName}`;

    let newPrefix = folderData.prefix === '/' ? '' : folderData.prefix;
    let newKey = `${newPrefix}${picData.fullName}`;
    return this.copyPicture(oldKey, newKey, picData.name);
  }
  private copyPicture(oldKey: string, newKey: string, newPicName: string): Observable<boolean> {
    return super.put<boolean>(this.apiUrl, { newKey, oldKey, newPicName })
      .pipe(
        catchError(error => super.handleError(error))
      );
  }

  deletePicture(picData: PicData): Observable<boolean> {
    let prefix = picData.prefix === '/' ? '' : picData.prefix;

    return super.delete<any>(`${this.apiUrl}?prefix=${prefix}&fullName=${picData.fullName}`)
      .pipe(
        catchError((error) => {
          return super.handleError(error);
        })
      )
  }

  searchPictures(query: string): Observable<PicData[]> {
    this.isSearchingPics$.next(true);

    return super.get<ApiResponse<PicData[]>>(this.apiUrl + '/query?query=' + query, true)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(response.error, response.stack);
            throw new Error(response.message);
          }

          this.searchedPics$.next(response.data);
          this.isSearchingPics$.next(false);
          return response.data;
        }),
        catchError(error => {
          this.isSearchingPics$.next(false);
          return super.handleError(error);
        })
      )
  }

  addTag(tag: Tag, picData: PicData): Observable<boolean> {
    let key = picData.prefix === '/' ? picData.fullName : `${picData.prefix}${picData.fullName}`;

    return super.post<ApiResponse<Tag[]>>(this.apiUrl + '/tags', {
      key,
      tagKey: tag.key,
      tagValue: tag.value
    }).pipe(
      map(() => {
        return true;
      }),
      catchError(super.handleError)
    );
  }

  getCachedPics(): PicData[] {
    let picsString = localStorage.getItem(this.picsKey);
    if (picsString == null || picsString === '') return [];
    return JSON.parse(picsString);
  }
  cachePics(pics: PicData[]) {
    let picsString = JSON.stringify(pics);
    localStorage.setItem(this.picsKey, picsString);
  }

}
