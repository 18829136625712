import { Component, OnInit } from '@angular/core';

import { AwsService } from './aws/aws.service';
import { UserService } from './admin/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private awsService: AwsService,
    private userService: UserService) {}

  ngOnInit() {
    this.userService.isLoggedIn()
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.awsService.initAws();
        }
      });
  }

}
