import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '../../utils/base.service';
import { UserService } from '../user.service';
import { Contact } from './contact';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {
  private contactApiUrl = `${environment.apiUrl}/contact`;

  constructor(private httpClient: HttpClient,
    userService: UserService) {
    super('ContactService', httpClient, userService);
  }

  contact(contactInfo: Contact): Observable<string> {
    return super.post<string>(`${this.contactApiUrl}`, contactInfo)
      .pipe(
        catchError(error => super.handleError(error))
      );
  }
}
