import { Component, OnDestroy } from '@angular/core';
import { ThemeService } from '../admin/theme/theme.service';
import { Theme } from '../admin/theme/themes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  template: `
  <footer [ngClass]="themeClass()">
    <label><a [ngClass]="textClass()" href="https://edwardmcnealy.com">&copy; {{copyrightDate}} Edward McNealy</a></label>
  </footer>
  `
})
export class FooterComponent implements OnDestroy {
  copyrightDate: number = new Date().getFullYear();
  currentTheme: Theme = null;
  private themeSub: Subscription;

  constructor(themeService: ThemeService) {
    this.themeSub = themeService.theme$.subscribe(theme => this.currentTheme = theme);
  }
  ngOnDestroy() {
    this.themeSub.unsubscribe();
  }

  themeClass() {
    if (this.currentTheme == null) return '';
    return `${this.currentTheme.name} ${this.currentTheme.bright} ${this.currentTheme.text}-text theme-transition`;
  }
  textClass() {
    if (this.currentTheme == null) return '';
    return `${this.currentTheme.text}-text`;
  }
}
