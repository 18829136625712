import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subscriber, Subject, forkJoin, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DriveImage } from '../dragon-drop/drive-image';
import FileData from '../dragon-drop/file-data';
import { BaseService } from '../base.service';
import Logger from '../logger';
import { AngualertService } from 'angualert';

declare const gapi: any;
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService extends BaseService {
  isAuthorized$: BehaviorSubject<boolean>;
  isSettingUp$: BehaviorSubject<boolean>;
  private authApi: any;
  private pickerApi: any;

  constructor(private ngZone: NgZone,
    private client: HttpClient,
    private angualertService: AngualertService) {
    super('GoogleDriveService', client, null);
    this.isAuthorized$ = new BehaviorSubject<boolean>(false);
    this.isSettingUp$ = new BehaviorSubject<boolean>(true);

    // Load the auth2
    gapi.load('auth2', () => {
      this.authApi = gapi.auth2;

      let gapiClientConfig = environment.gapiClientConfig;
      const gapiOptions = {
        apiKey: gapiClientConfig.apiKey,
        clientId: gapiClientConfig.clientId,
        discoveryDocs: gapiClientConfig.discoveryDocs,
        scope: gapiClientConfig.scope,
      };

      this.authApi.init(gapiOptions).then(() => {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(isSignedIn => {
          this.ngZone.run(() => {
            this.isAuthorized$.next(isSignedIn);
            if (isSignedIn) {
              this.angualertService.success('Signed into Google!');
            } else {
              this.angualertService.success('Signed out of Google.');
            }
          });
        });

        // Notify the initial signed in state
        this.ngZone.run(() => {
          const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
          this.isAuthorized$.next(isSignedIn);
          this.isSettingUp$.next(false)
        });
      }, (error) => {
        const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
        this.isAuthorized$.next(isSignedIn);
        this.isSettingUp$.next(false);
        Logger.error(error);
      });
    });

    // Load the Drive picker
    gapi.load('picker', () => this.pickerApi = gapi.picker.api);
  }

  signIn() {
    gapi.auth2.getAuthInstance().signIn();
  }

  signOut() {
    gapi.auth2.getAuthInstance().signOut();
  }

  revokeAccess() {
    gapi.auth2.getAuthInstance().disconnect();
  }

  import(): Observable<FileData[]> {
    return new Observable<FileData[]>(subscriber => {
      if (this.pickerApi == null) {
        gapi.load('picker', () => {
          this.pickerApi = gapi.picker.api;
          this.openPicker(subscriber);
        });
      } else {
        this.openPicker(subscriber)
      }
    }).pipe(catchError(error => {
      Logger.error(error);
      return throwError('There was a problem trying importing with Google Drive. Please refresh the page and try again, or contact Ed if the problem continues.');
    }));
  }

  private openPicker(subscriber: Subscriber<FileData[]>) {
    const token = this.getToken();
    if (token == null) {
      return subscriber.error('You need to sign in with your Google account and provide access.');
    }

    let view = new google.picker.DocsView(this.pickerApi.ViewId.DOCS);
    view.setMimeTypes('image/png,image/jpeg,image/jpg');
    let picker = new google.picker.PickerBuilder()
      .enableFeature(this.pickerApi.Feature.MULTISELECT_ENABLED)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(environment.gapiClientConfig.projectId)
      .setOAuthToken(token)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setDeveloperKey(environment.gapiClientConfig.developerKey)
      .setCallback((data) => { this.pickerCallback(data, subscriber); })
      .build();
    picker.setVisible(true);
  }

  private pickerCallback(data, subscriber: Subscriber<FileData[]>) {
    if (data.action == this.pickerApi.Action.PICKED) {
      if (data.docs == null || data.docs.length === 0) {
        return subscriber.error('No images picked.');
      }
      let images: DriveImage[] = data.docs.map(doc => doc as DriveImage);

      let token = this.getToken();
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token
      });

      let downloadTasks = new Array<Observable<FileData>>();
      for (let image of images) {
        let url = `https://www.googleapis.com/drive/v2/files/${image.id}?alt=media`
        const task = this.client.get(url, { responseType: 'blob', headers })
          .pipe(
            map(blob => {
              return new FileData(image.name, blob);
            }),
            catchError(error => {
              return super.handleError(error);
            })
          );
        downloadTasks.push(task);
      }

      forkJoin(downloadTasks)
        .subscribe(files => {
          this.ngZone.run(() => {
            subscriber.next(files);
          })
        });
    }
  }

  private getToken() {
    const currentUser = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
    const token = currentUser.access_token;
    return token;
  }
}
