import { Injectable } from '@angular/core';
import * as AWS from "aws-sdk/global";

import { CognitoService } from '../utils/cognito.service';
import { environment } from '../../environments/environment';
import Logger from '../utils/logger';
/**
 * Based on source from Vladimir Budilov @ https://github.com/awslabs/aws-cognito-angular2-quickstart
 */
@Injectable({
  providedIn: 'root'
})
export class AwsService {
  public static firstLogin: boolean = false;
  public static runningInit: boolean = false;

  constructor(private cognitoService: CognitoService) {
    AWS.config.region = environment.region;
  }

  /**
   * This is the method that needs to be called in order to init the aws global creds
   */
  public initAws() {

    if (AwsService.runningInit) {
      // Need to make sure I don't get into an infinite loop here, so need to exit if this method is running already
      return;
    }
    AwsService.runningInit = true;

    let mythis = this;
    this.cognitoService.getCognitoUser()
      .subscribe(data => {
        if (data.idToken) {
          return this.setupAWS(data.idToken);
        }
        Logger.log('No token for user');
      }, error => {
        Logger.error(error);
      });
  }


  /**
   * Sets up the AWS global params
   */
  private setupAWS(token: string): void {
    this.addCognitoCredentials(token);
    AwsService.runningInit = false;
  }

  private addCognitoCredentials(token: string): void {
    let creds = this.cognitoService.buildCognitoCreds(token);
    AWS.config.credentials = creds;
    creds.get((err) => {});
  }

}
