import { Component } from '@angular/core';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styles: [
    `#notFound {
      width: 100%;
      height: 100vh;
      background: url(${require('../../assets/waterfall.jpg')}) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-right: 0px;
    }
    h2 {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  `
  ]
})
export class NotFoundComponent {
}
