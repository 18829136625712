export enum LoginCodes {
  SUCCESS = 'SUCCESS',
  PASSWORD_UPDATE = 'PasswordUpdateRequired',
  PASSWORD_RESET = 'PasswordResetRequiredException',  // This value comes from AWS
  CODE_MISMATCH = 'CodeMismatchException',            // This value comes from AWS
  LIMIT_EXCEEDED = 'LimitExceededException',          // This value comes from AWS
  INVALID_PARAMETER = 'InvalidParameterException',    // This value comes from AWS
  NOT_AUTHORIZED = 'NotAuthorizedException',          // This value comes from AWS
  INVALID_PASSWORD = 'InvalidPasswordException',      // This value comes from AWS
  ERROR = 'ERROR'
}

export interface LoginResult {
  code: LoginCodes;
  message?: string;
}
