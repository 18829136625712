export class User {
  username: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
  token: string;
  verificationCode: string;

  constructor(username?: string) {
    this.username = username;
  }
}
