import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Subscription, forkJoin, throwError } from 'rxjs';
import { debounceTime, map, catchError } from 'rxjs/operators'

import { User } from '../admin/user';
import { PicService } from '../pic/pic.service';
import { FolderService } from '../folder/folder.service';
import { AngualertService } from 'angualert';
import { PicData, Tag } from '../pic/pic-data';
import { FolderData } from '../folder/folder-data';
import { WINDOW } from '../utils/window.service';
import { environment } from '../../environments/environment';
import FileData from '../utils/dragon-drop/file-data';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, OnDestroy {
  currentFolder: FolderData;
  routePath: string;                  // The current url path, used for getting folders
  pics: PicData[] = [];               // The pics for the currently selected folder for a route
  arePicsLoading: boolean = true;     // Are we loading pics from the server?
  isPicUploading: boolean = false;    // Are we uploading a pic?
  folderContainerClass: string = '';
  user: User = null;
  baseFolderUrl = '/';
  folderSearchText = '';
  folderSearchControl = new FormControl();
  private foldersSub: Subscription
  private routerSub: Subscription;

  constructor(private router: Router,
    titleService: Title,
    @Inject(WINDOW) private window: Window,
    private picService: PicService,
    private folderService: FolderService,
    private angualertService: AngualertService) {
    titleService.setTitle(`${environment.appName}`);

    this.routerSub = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routePath = decodeURI(event.url);  // Need to decode for things like spaces

        // Whenever the route changes, we need to reload the displayed pictures for the current folder
        // This can happen in folder.component by selecting a folder, or by changing the url manually
        const stateFolder = this.router.getCurrentNavigation().extras.state as FolderData
        this.currentFolder = stateFolder;
        if (this.currentFolder == null) return;
        this.getPictures(this.currentFolder);
      }
    });
  }
  ngOnInit() {
    this.foldersSub = this.folderService.folderDataMap$.subscribe(folderDataMap => {
      if (folderDataMap == null) return;

      this.currentFolder = folderDataMap[this.routePath];
      if (this.currentFolder == null) {
        this.router.navigate([environment.notFoundUrl]);
        return;
      }

      this.getPictures(this.currentFolder);
    }, (error) => {
      this.angualertService.error(error.message);
    });

    this.folderSearchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(folderSearchText => this.folderService.filterFolders(folderSearchText));
  }
  ngOnDestroy() {
    this.foldersSub.unsubscribe();
    this.routerSub.unsubscribe();
  }

  handleLoginChange(user: User) {
    this.user = user;
  }

  /**
   * Gets all pictures in a folder.
   * @param folder The folder to get pictures for.
   */
  private getPictures(folder: FolderData) {
    if (!folder.shouldLoadPics) {
      // Just use the cached pics until page refresh
      this.pics = folder.pics;
      this.arePicsLoading = false;
      return;
    }

    this.arePicsLoading = true;
    this.picService.getPictures(folder.prefix)
      .subscribe(pics => {
        this.arePicsLoading = false;

        let sorted = this.getSortedPics(pics);
        // Cache the pics in this folder until page reload
        // This also makes the folder.pics and this.pics reference the same array, so keep that in mind. May want to change
        folder.pics = sorted;
        folder.shouldLoadPics = false;
        this.pics = sorted;
      }, (error) => {
        this.angualertService.error(error.message);
        this.arePicsLoading = false;
      });
  }

  onPicDeleted(picData: PicData) {
    let index = this.pics.indexOf(picData);
    // Remove the deleted pic
    this.pics.splice(index, 1);
    // Get any pics whose order needs to be updated
    let needsToUpdate = this.pics.splice(index).map(p => {
      p.order -= 1
      return p;
    });
    // Update the orders
    needsToUpdate.forEach(p => {
      this.picService.addTag(new Tag('order', String(p.order)), p)
        .subscribe(() => { });
    });

    this.pics = this.pics.concat(needsToUpdate);
  }

  onFilesReady(files: FileData[]) {
    if (files.length === 0) {
      return this.angualertService.warn('You need to include some files to upload.');
    }

    this.isPicUploading = true;
    let folder = this.currentFolder;

    let order;
    if (this.currentFolder.pics.length > 0) {
      order = this.currentFolder.pics[this.currentFolder.pics.length - 1].order;
    } else {
      order = 0;
    }

    let picUploadTasks = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let task = this.picService.uploadPicture(file, folder, order++)
        .pipe(
          map(picData => {
            if (picData.errorData == null) {
              this.pics.push(picData);
              folder.pics = this.pics
            } else {
              if (picData.errorData.statusCode === 409) {
                this.angualertService.warn(picData.errorData.message);
              } else {
                this.angualertService.error(picData.errorData.message);
              }
            }
            return picData;
          }),
          catchError(error => {
            this.angualertService.error(error.message);
            return throwError(error);
          }),
        )
      picUploadTasks.push(task);
    }

    forkJoin(picUploadTasks).subscribe(pics => {
      this.isPicUploading = false;

      if (pics.length > 0) {
        // Sort all pics
        this.pics = this.getSortedPics(this.pics);
        folder.pics = this.pics;

        const successPics = pics.filter(p => p.errorData == null);

        let message = successPics.length === 1 ?
          `Uploaded ${successPics[0].fullName}!` :
          `Uploaded ${successPics.length} pics!`;
        this.angualertService.success(message);
      }
    }, () => {
      this.isPicUploading = false;
    })
  }

  onDropSuccess(item: PicData, index: number) {
    let oldOrder = item.order;
    let newOrder = index;

    let picsToUpdate = [];
    if (oldOrder > newOrder) {
      // This pic is moving back in order
      let startIndex = this.pics.indexOf(item);
      for (let i = startIndex; i <= oldOrder && i < this.pics.length; i++) {
        this.pics[i].order = i;
        picsToUpdate.push(this.pics[i]);
      }
    } else {
      // This pic is moving forward in order
      for (let i = oldOrder; i <= newOrder; i++) {
        this.pics[i].order = i;
        picsToUpdate.push(this.pics[i]);
      }
    }

    picsToUpdate.forEach(p => {
      this.picService.addTag(new Tag('order', String(p.order)), p)
        .subscribe(() => { });
    });
  }

  /**
   * Returns a list of the pics, sorted by their order.
   */
  private getSortedPics(pics: PicData[]): PicData[] {
    return pics.sort((a, b) => a.order - b.order);
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || 0;
    this.folderContainerClass = number > 65 ? 'd-fixed' : '';
  }
}
