// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  appName: 'Jerry McNealy',

  notFoundUrl: '404',

  // The url to the API Gateway
  // apiUrl: 'https://www.mcnedward.com/api-beta/jerry',
  apiUrl: 'https://c172g0150a.execute-api.us-east-1.amazonaws.com/beta/jerry',

  // My AWS region
  region: 'us-east-1',

  identityPoolId: 'us-east-1:e0a84ddb-c5a5-4b1a-b9af-7c552f11fc65',
  userPoolId: 'us-east-1_orZVCuojY',
  appClientId: '4jca7l8nnjb4i1njm5p4s4m89u',

  cognitoIDPEndpoint: '',
  cognitoIdentityEndpoint: '',
  stsEndpoint: '',
  dynamodbEndpoint: '',
  s3Endpoint: '',

  // DynamoDB configs for jerrymcnealy table
  themeTable: 'jerrymcnealy-test-themes',
  themeTableKey: 'jerryMcnealyThemes',

  gapiClientConfig: {
    clientId: '313512038043-tpk1bmrqajdiqikn9kabu9v534ohh0i1.apps.googleusercontent.com',
    apiKey: 'AIzaSyDCTlmFdgJDj-adQP8aFH8YA9yVyA-X0Rc',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    scope: 'https://www.googleapis.com/auth/drive',
    projectId: 'mcnedward-146220',
    developerKey: 'AIzaSyDCTlmFdgJDj-adQP8aFH8YA9yVyA-X0Rc',
    redirect_uris: ['http://localhost:4200', 'http://localhost'],
    javascript_origins: ['http://localhost:4200', 'http://localhost'],
    auth_url: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://accounts.google.com/o/oauth2/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
