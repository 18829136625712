import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseService } from '../utils/base.service';
import { UserService } from '../admin/user.service';
import { FolderData, FolderDataMap, FolderRenameData } from './folder-data';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { PicData, Tag } from '../pic/pic-data';
import { PicService } from '../pic/pic.service';
import ApiResponse from '../utils/api-response';
import Logger from '../utils/logger';

@Injectable({
  providedIn: 'root'
})
export class FolderService extends BaseService {
  private folderAddedSource = new Subject<FolderData>();
  folderAdded$ = this.folderAddedSource.asObservable();
  private folderRenamedSource = new Subject<FolderRenameData[]>();
  folderRenamed$ = this.folderRenamedSource.asObservable();
  areFoldersLoading$ = new BehaviorSubject<boolean>(false);

  private folderDataMap: FolderDataMap;
  folderDataMap$: BehaviorSubject<FolderDataMap>;

  private selectedFolder: FolderData = null;
  selectedFolder$ = new Subject<FolderData>();

  private apiUrl = `${environment.apiUrl}/folders`;
  private folderMapKey: string = 'FOLDER_MAP_KEY';

  constructor(httpClient: HttpClient,
    loginService: UserService,
    private router: Router,
    private picService: PicService) {
    super('Folder Service', httpClient, loginService);
    this.folderDataMap = null;
    this.folderDataMap$ = new BehaviorSubject<FolderDataMap>(this.folderDataMap);
  }

  /**
   * Loads the folder map of all the folders in images.jerrymcnealy.com S3 bucket.
   */
  loadFolders(): Observable<boolean> {
    this.areFoldersLoading$.next(true);
    return super.get<ApiResponse<FolderDataMap>>(this.apiUrl, true)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(`Could not load folders. Message: ${response.message} - Status: ${response.statusCode} Error: ${response.error} - Stack: ${response.stack}`);
            throw throwError('There was a problem getting folders. Please refresh the page to try again.');
          }
          const folderMap = response.data;

          this.cacheFolderMap(folderMap);

          this.folderDataMap = folderMap;
          this.folderDataMap$.next(this.folderDataMap);
          this.areFoldersLoading$.next(false);

          return true;
        }),
        catchError(error => {
          this.areFoldersLoading$.next(false);
          return super.handleError(error);
        })
      );
  }

  /**
   * Creates a new folder.
   * @param folder The new folder to create
   */
  addFolder(folder: FolderData): Observable<boolean> {
    this.areFoldersLoading$.next(true);

    return super.post<ApiResponse<string>>(this.apiUrl, folder)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(`Could not load folders. Message: ${response.message} - Status: ${response.statusCode} Error: ${response.error} - Stack: ${response.stack}`);
            throw throwError('There was a problem getting folders. Please refresh the page to try again.');
          }
          const folderId = response.data;
          folder.id = folderId;

          // Add to the parent's subfolders array
          this.folderDataMap[folder.parentUrl].subFolderUrls.push(folder.url);
          // Add to the data map
          this.folderDataMap[folder.url] = folder;
          // Notify everyone
          // Hits core.component to update pictures
          // Hits folder.component to select that folder
          // Hits side-nav.component?
          this.folderDataMap$.next(this.folderDataMap);

          // Notify everyone that a new folder was added
          this.folderAddedSource.next(folder);
          this.areFoldersLoading$.next(false);

          return folderId != null && folderId !== '';
        }),
        catchError(error => {
          this.areFoldersLoading$.next(false);
          return super.handleError(error);
        })
      );
  }

  /**
   * Renames a folder.
   * @param newName The new name for the folder
   * @param folder The folder to rename
   */
  renameFolder(newName: string, folder: FolderData): Observable<FolderRenameData[]> {
    let r = new RegExp(`${folder.name}/$`);
    let newPrefix = folder.prefix.replace(r, newName + '/');
    let folderRenameData = {
      newName,
      oldName: folder.name,
      oldPrefix: folder.prefix,
      newPrefix: newPrefix
    } as FolderRenameData;

    return this.putFolder(folderRenameData);
  }

  moveFolder(oldFolder: FolderData, newFolder: FolderData): Observable<FolderRenameData[]> {
    let newPrefix;
    if (newFolder.prefix === '/') {
      newPrefix = oldFolder.name + '/'
    } else {
      newPrefix = newFolder.prefix + oldFolder.name + '/'
    }
    return this.putFolder({
      newName: oldFolder.name,
      oldName: oldFolder.name,
      oldPrefix: oldFolder.prefix,
      newPrefix
    } as FolderRenameData);
  }

  private putFolder(folderRenameData: FolderRenameData): Observable<FolderRenameData[]> {
    this.areFoldersLoading$.next(true);

    return super.put<ApiResponse<FolderRenameData[]>>(this.apiUrl, folderRenameData)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(`Could not update folder. Message: ${response.message} - Status: ${response.statusCode} Error: ${response.error} - Stack: ${response.stack}`);
            throw throwError('There was a problem updating folder.');
          }

          const renamedFolders = response.data;
          let renamedFolder: FolderData, renameData: FolderRenameData;
          let newRoutePath: string = null;
          const currentRoute = this.router.url;

          for (let i = renamedFolders.length - 1; i >= 0; i--) {
            let item = renamedFolders[i];
            let folder = this.folderDataMap[item.oldUrl];
            if (i === 0) {
              renamedFolder = folder;
              renameData = item;
            }

            // Update each subfolder url
            for (let i = 0; i < folder.subFolderUrls.length; i++) {
              let oldSubFolderUrl = folder.subFolderUrls[i];
              let newSubFolderUrl = oldSubFolderUrl.replace(item.oldUrl, item.newUrl);
              folder.subFolderUrls.splice(i, 1, newSubFolderUrl);

              // Reset the sub folder's pics
              this.folderDataMap[newSubFolderUrl].shouldLoadPics = false;

              // If we are currently in a subfolder, we need to move to the new url once this process is complete
              if (currentRoute === oldSubFolderUrl) {
                newRoutePath = newSubFolderUrl;
              }
            }

            // Update the folder
            folder.name = item.newName;
            folder.prefix = item.newPrefix;
            folder.url = item.newUrl;
            folder.parentUrl = item.newUrl.replace(`/${item.newName}`, '');
            if (folder.parentUrl === '') folder.parentUrl = '/';  // For the base folder

            // Add the updated folder to the map
            this.folderDataMap[item.newUrl] = folder;
            // Delete the old folder from the map
            delete this.folderDataMap[item.oldUrl];
          }

          // Add the actual renamed folder to its parent as well
          let parent = this.folderDataMap[renamedFolder.parentUrl];
          let index = parent.subFolderUrls.indexOf(renameData.oldUrl)
          if (index === -1) {
            // This is a folder being dragged and dropped into another (so the folder won't exist in the parent subFolderUrls yet)
            parent.subFolderUrls.push(renamedFolder.url);
            // Also need to remove the folder from its old parent
            let oldParentUrl = renameData.oldUrl.replace(`/${renameData.oldName}`, '')
            if (oldParentUrl === '') oldParentUrl = '/';  // For the base folder
            let oldParent = this.folderDataMap[oldParentUrl];
            oldParent.subFolderUrls.splice(oldParent.subFolderUrls.indexOf(renameData.oldUrl), 1);
          } else {
            parent.subFolderUrls.splice(index, 1, renamedFolder.url);
          }

          // If we are currently in the renamed folder, we have to navigate to the new url
          let navigateUrl;
          if (currentRoute === renameData.oldUrl)
            navigateUrl = renamedFolder.url;
          else if (newRoutePath != null)
            navigateUrl = newRoutePath;

          if (navigateUrl != null && navigateUrl !== '') {
            this.router.navigate([navigateUrl])
              .then(() => {
                // Notify everyone that the folder structure has changed
                this.folderDataMap$.next(this.folderDataMap);
                // Stop loading
                this.areFoldersLoading$.next(false);
              });
          } else {
            // Notify everyone that the folder structure has changed
            this.folderDataMap$.next(this.folderDataMap);
            // Stop loading
            this.areFoldersLoading$.next(false);
          }

          return renamedFolders;
        }),
        catchError(error => {
          this.areFoldersLoading$.next(false);
          return super.handleError(error);
        })
      );
  }

  /**
   * Deletes a folder from the S3 bucket.
   * @param folder The folder to delete
   */
  deleteFolder(folder: FolderData): Observable<boolean> {
    this.areFoldersLoading$.next(true);

    return super.delete<ApiResponse<boolean>>(`${this.apiUrl}?prefix=${folder.prefix}&name=${folder.name}`)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(`Could not delete folder. Message: ${response.message} - Status: ${response.statusCode} Error: ${response.error} - Stack: ${response.stack}`);
            throw throwError('There was a problem deleting folder. Please refresh the page to try again.');
          }

          delete this.folderDataMap[folder.url];
          const parent = this.folderDataMap[folder.parentUrl];
          parent.subFolderUrls.splice(parent.subFolderUrls.indexOf(folder.url), 1);

          // If we are currently in the deleted folder, we have to navigate to the parent
          const currentRoute = this.router.url;
          if (currentRoute === folder.url) {
            this.router.navigate([parent.url])
              .then(() => {
                // Notify everyone that a folder was delete
                this.folderDataMap$.next(this.folderDataMap);
                this.areFoldersLoading$.next(false);
              })
          } else {
            // Notify everyone that a folder was delete
            this.folderDataMap$.next(this.folderDataMap);
            this.areFoldersLoading$.next(false);
          }

          return response.data;
        }),
        catchError((error) => {
          this.areFoldersLoading$.next(false);
          return super.handleError(error);
        })
      );
  }

  picFolderChange(picData: PicData, newFolder: FolderData) {
    // Remove the pic from the old folder, and trigger a pic reload next time the new folder is selected
    let key = '/' + picData.prefix.replace(/\/$/, '');
    let oldFolder = this.folderDataMap[key];
    oldFolder.pics.splice(oldFolder.pics.indexOf(picData), 1);
    newFolder.shouldLoadPics = true;

    // Update the orders of the current folder's pics
    let picsToUpdate = [];
    let index = picData.order;
    for (let i = index; i < oldFolder.pics.length; i++) {
      oldFolder.pics[i].order = i;
      picsToUpdate.push(oldFolder.pics[i]);
    }

    picsToUpdate.forEach(p => {
      this.picService.addTag(new Tag('order', String(p.order)), p)
        .subscribe(() => { });
    });
  }

  filterFolders(folderSearchText: string) {
    if (folderSearchText == null || folderSearchText === '') {
      this.folderDataMap$.next(this.folderDataMap);
      return;
    }

    let firstMatchedFolder;
    let newFolderMap = {};
    Object.entries(this.folderDataMap).forEach(([key, value]) => {
      if (key === '/') {
        return newFolderMap[key] = value;
      }

      if (value.name.includes(folderSearchText)) {
        if (newFolderMap[key] == null) {
          newFolderMap[key] = value;
          value.isOpened = true;
          value.isMarkedForceOpen = true;

          // Get the first folder which a matching name to the search text
          if (firstMatchedFolder == null && value.name === folderSearchText) {
            firstMatchedFolder = value;
          }

          // Add all parents recursively
          this.addParentToMap(newFolderMap, value.parentUrl);
        }
      }
    });

    // We are filtering folders, so need to check if the route should be updated
    const currentRoute = this.router.url;
    let navigateUrl;
    if (firstMatchedFolder != null) {
      navigateUrl = firstMatchedFolder.url;
    } else {
      if (folderSearchText !== currentRoute) {
        let searchedFolder = newFolderMap['/' + folderSearchText];
        if (searchedFolder != null) {
          navigateUrl = folderSearchText;
        } else {
          navigateUrl = '/';
        }
      }
    }
    if (navigateUrl != null) {
      this.router.navigate([navigateUrl]).then(() => this.folderDataMap$.next(newFolderMap));
    } else {
      // Otherwise just update the map
      this.folderDataMap$.next(newFolderMap);
    }
  }
  private addParentToMap(newFolderMap: FolderDataMap, parentUrl: string) {
    if (newFolderMap[parentUrl] == null) {
      newFolderMap[parentUrl] = this.folderDataMap[parentUrl];
      newFolderMap[parentUrl].isOpened = true;
      newFolderMap[parentUrl].isMarkedForceOpen = true;

      this.addParentToMap(newFolderMap, newFolderMap[parentUrl].parentUrl);
    }
  }

  selectFolder(folder: FolderData) {
    this.selectedFolder = folder;
    this.selectedFolder$.next(this.selectedFolder);
  }
  getSelectedFolder(): FolderData {
    return this.selectedFolder;
  }

  // TODO REMOVE!!!!!!!!!!!!!!!!!!!!
  getCachedFolderMap(): FolderDataMap {
    let folderMapString = localStorage.getItem(this.folderMapKey);
    if (folderMapString == null || folderMapString === '') return null;
    let folderMap = JSON.parse(folderMapString) as FolderDataMap;
    if (folderMap == null) return null;
    return folderMap;
  }
  cacheFolderMap(folderMap: FolderDataMap) {
    if (folderMap == null) return;
    let folderMapString = JSON.stringify(folderMap);
    localStorage.setItem(this.folderMapKey, folderMapString);
  }

}
