import { Component, HostListener, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';

import { forkJoin } from 'rxjs';

import { FileManager } from './file-manager.component';
import { FileService } from './file.service';
import { AngualertService } from 'angualert';
import { FolderData } from '../../folder/folder-data';
import { Directory } from './directory';
import FileData from './file-data';

@Component({
  selector: 'dragon-drop',
  templateUrl: './dragon-drop.component.html',
  styleUrls: ['./dragon-drop.component.scss']
})
export class DragonDrop implements OnInit {
  @Input() public defaultMessage: string;
  @Input() public folder: FolderData;
  @Input() public isLoading: boolean;
  @Output() public filesReady: EventEmitter<FileData[]> = new EventEmitter;
  @ViewChild('fileManager') fileManager: FileManager;
  public errorMessage: string;
  public isDragging: boolean = false;
  public dragOverState: string;
  public modalId: string = 'dragonDropModal';
  private defaultDragAreaMessage = 'Drag files here';
  private dragoverClass = 'dragover';
  private droppedClass = 'dropped';
  private errorClass = 'dragover-error';
  private noClass = '';

  constructor(private fileService: FileService,
    private angualertService: AngualertService) { }

  ngOnInit() {
    if (this.defaultMessage == null || this.defaultMessage === '') {
      this.defaultMessage = this.defaultDragAreaMessage;
    }
  }

  importLocal() {
    this.fileManager.importLocalFiles();
  }

  onFilesReady(files: FileList) {
    if (files == null || files.length === 0) {
      return this.angualertService.warn('No files to upload.');
    }
    let fileDataList = new Array<FileData>();
    for (let i = 0; i < files.length; i++) {
      fileDataList.push(new FileData(files[i].name, files[i]));
    }
    this.filesReady.emit(fileDataList);
  }

  onDriveFilesReady(driveImages: FileData[]) {
    this.filesReady.emit(driveImages);
  }

	/**
	 * Called after the DragonDrop has finished building the directory.
	 * @param directory The Directory, ready to be uploaded
	 */
  private handleSuccess(directory: Directory) {
    this.dragOverState = this.noClass;
    this.isLoading = false;
    this.fileService.uploadDirectory(directory);
  }
  /**
   * Called after the DragonDrop has finised getting files.
   * @param files The files to be uploaded
   */
  private handleFilesSuccess(files: FileList) {
    this.dragOverState = this.noClass;
    this.isLoading = false;
    this.onFilesReady(files);
  }

  private handleError(error) {
    this.errorMessage = 'Something went wrong when preparing your files, please try again.';
    this.dragOverState = this.errorClass;
    this.isLoading = false;
  }

	/**
	 * Use this to cancel the file upload.
	 * @param message The message to display after canceling
	 */
  private cancel() {
    this.dragOverState = this.noClass;
    this.isLoading = false;
  }

  @HostListener('dragenter', ['$event'])
  public dragEnter(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    this.dragOverState = this.dragoverClass;
    this.errorMessage = '';
    this.isDragging = true;
  }
  @HostListener('dragleave', ['$event'])
  public dragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOverState = this.noClass;
    this.isDragging = false;
  }
  @HostListener('dragover', ['$event'])
  public dragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    this.dragOverState = this.dragoverClass;
    this.isDragging = true;
  }

  @HostListener('drop', ['$event'])
  public drop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragging = false;

    if (event) {
      let dataTransfer = event.dataTransfer;
      let items = dataTransfer.items;
      let files = dataTransfer.files;

      if (!dataTransfer.types.includes('Files')) {
        this.dragOverState = this.noClass;
        return;
      }

      this.isLoading = true;

      if (items.length === 1) {
        let item = items[0].webkitGetAsEntry();
        if (item.isFile) {
          this.handleFilesSuccess(files);
          // this.handleFiles(items).then(
          //   (files) => this.handleFilesSuccess(files),
          //   (error) => this.handleError(error));
        } else {
          // This is a directory. I'm not allowing directories for now
          // this.handleDirectory(item).then(
          //   (result) => this.handleSuccess(result),
          //   (error) => this.handleError(error));
        }
      } else {
        this.handleFilesSuccess(files);
        // this.handleFiles(items).then(
        //   (files) => this.handleFilesSuccess(files),
        //   (error) => this.handleError(error));
      }
    }
  }

	/**
	 * Creates a promise for a list of Files based on the data items.
	 * @param items The data items. This should be a list of files
	 */
  private handleFiles(items: DataTransferItemList): Promise<File[]> {
    // Get a promise for each of the file items
    let filePromises = [];
    for (let i = 0, l = items.length; i < l; i++) {
      let entry = items[i].webkitGetAsEntry();
      filePromises.push(this.createFilePromise(entry));
    }
    return Promise.all(filePromises);
  }

	/**
	 * Recursively handles a directory, including subdirectories and files.
	 * @param directoryId The id for the directory.
	 * @param item The data directory item.
	 */
  private handleDirectory(item: any): Promise<Directory> {
    let reader = item.createReader();
    // If the top level directory has not been created yet, do it now
    let directory = new Directory(item.name);

    return new Promise((resolve) => {
      let filePromises = new Array<Promise<any>>();
      let directoryPromises = new Array<Promise<Directory>>();

      reader.readEntries((entries) => {
        for (let entry of entries) {
          if (entry.isFile) {
            // Create a file promise
            filePromises.push(this.createFilePromise(entry));
          } else {
            // Create a directory promise, to be completed when all files inside this entry are resolved
            directoryPromises.push(this.createDirectoryPromise(entry));
          }
        }

        // ForkJoin both the directory and file promises
        forkJoin(Promise.all(filePromises), Promise.all(directoryPromises))
          .subscribe((results) => {
            let files = results[0];
            let directories = results[1];

            for (let d of directories) {
              directory.addDirectory(d);
            }
            // Add all the files and directories to this directory
            for (let file of files) {
              directory.addFile(file);
            }
            // This directory is finished, so we can resolve it
            resolve(directory);
          }, (err) => this.handleError(err));
      });
    });
  }

	/**
	 * Creates a Promise for a File
	 * @param entry The data file item.
	 */
  private createFilePromise(entry: any): Promise<File> {
    return new Promise((resolve) => {
      entry.file((file) => {
        resolve(file);
      });
    });
  }

	/**
	 * Creates a Promise for a Directory.
	 * @param entry The data directory item.
	 */
  private createDirectoryPromise(entry: any): Promise<Directory> {
    return new Promise((resolve) => {
      // Get all the directory promises for this directory entry
      let promise = this.handleDirectory(entry);

      promise.then((directory) => {
        resolve(directory);
      }, (err) => this.handleError(err));
    });
  }
}
