/**
 * Class used to hold data for a file or blob that will be uploaded to S3
 */
export default class FileData {
  name: string;
  data: File|Blob;

  constructor(name: string, data: File|Blob) {
    this.name = name;
    this.data = data;
  }
}
