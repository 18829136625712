import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../../utils/base.service';
import { UserService } from '../../admin/user.service';
import { Directory } from '../dragon-drop/directory';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  constructor(httpClient: HttpClient,
    loginService: UserService) {
    super('File Service', httpClient, loginService);
  }

  uploadFiles(files: File[]) {
    console.log('uploading', files);
  }

  uploadDirectory(directory: Directory) {
    console.log('uploading', directory);
  }
}
