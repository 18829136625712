import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import * as M from 'materialize-css';

import { UserService } from './user.service';
import { ThemeService } from './theme/theme.service';
import { Theme } from '../admin/theme/themes';
import { User } from './user';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
  public user: User;
  // @ViewChild('themeTab') private themeTab: ElementRef;
  // @ViewChild('settingsTab') private settingsTab: ElementRef;
  // @ViewChild('helpTab') private helpTab: ElementRef;
  private loginUrl: string = '/admin/login';
  public currentTheme: Theme = null;
  // private featureChangeRequestSub: Subscription;
  private themeSub: Subscription;
  private tabInstance: any;

  constructor(private router: Router,
    titleService: Title,
    // featureService: FeatureService,
    themeService: ThemeService,
    private loginService: UserService) {
    titleService.setTitle('Jerry Pics | Admin');

    // this.featureChangeRequestSub = featureService.changeRequest$.subscribe(feature => {
    //   if (feature.tab === 'settings') {
    //     return this.settingsTab.nativeElement.click()
    //   }
    //   if (feature.tab === 'theme') {
    //     return this.themeTab.nativeElement.click()
    //   }
    //   if (feature.tab === 'help') {
    //     return this.helpTab.nativeElement.click()
    //   }
    // });
    this.themeSub = themeService.theme$.subscribe(theme => {
      this.currentTheme = theme;
    });
  }

  ngOnInit() {
    this.loginService.getUser()
      .subscribe(user => {
        this.user = user;
      }, () => {
        this.router.navigate([this.loginUrl]);
      });
  }

  ngAfterViewInit() {
    var el = document.querySelector('.tabs');
    this.tabInstance = M.Tabs.init(el, {});
  }

  ngOnDestroy() {
    // this.featureChangeRequestSub.unsubscribe();
    this.themeSub.unsubscribe();
    if (this.tabInstance != null)
      this.tabInstance.destroy();
  }

  public handleLoginChange(user: User) {
    this.user = user;
    if (user == null) {
      this.router.navigate([this.loginUrl]);
    }
  }
}
