import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngualertModule } from 'angualert';

import { DndModule } from 'ng2-dnd';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';
import { CoreComponent } from './core/core.component';
import { SearchComponent } from './search/search.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './admin/login/login.component';
import { ThemeComponent } from './admin/theme/theme.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { HelpComponent } from './admin/help/help.component';
// import { Feature } from './admin/feature/feature.component';
// import { FeatureBox } from './admin/feature/feature-box.component';
import { AuthDirective } from './admin/auth.directive';
import { FolderComponent } from './folder/folder.component';
import { DragonDrop } from './utils/dragon-drop/dragon-drop.component';
import { GoogleDriveComponent } from './utils/google-drive/google-drive.component';
import { ChipsComponent } from './utils/chips/chips.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FileManager } from './utils/dragon-drop/file-manager.component';
import { PicComponent } from './pic/pic.component';
import { ProgressBar } from './utils/progress/progress-bar';
import { ProgressSpinner } from './utils/progress/progress-spinner';
import { PasswordStrength } from './utils/password-strength/password-strength.component';
import { AdDirective } from './utils/ad.directive';

import { WINDOW_PROVIDERS } from './utils/window.service';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    SideNavComponent,
    FooterComponent,
    CoreComponent,
    SearchComponent,
    AdminComponent,
    ThemeComponent,
    SettingsComponent,
    HelpComponent,
    LoginComponent,
    AuthDirective,
    // Feature,
    // FeatureBox,
    FolderComponent,
    DragonDrop,
    FileManager,
    GoogleDriveComponent,
    ChipsComponent,
    PicComponent,
    NotFoundComponent,
    ProgressBar,
    ProgressSpinner,
    PasswordStrength,
    AdDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngualertModule,
    DndModule.forRoot(),
    PasswordStrengthBarModule
  ],
  providers: [
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
