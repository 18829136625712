import { Component } from '@angular/core';

import { ContactService } from './contact.service';
import { AngualertService } from 'angualert';
import { Contact } from './contact';

@Component({
  selector: 'help',
  templateUrl: './help.component.html'
})
export class HelpComponent {
  contact: Contact = new Contact();
  isLoading: boolean = false;

  constructor(private angualertService: AngualertService,
    // private featureService: FeatureService,
    private contactService: ContactService) {
  }

  contactEd() {
    this.isLoading = true;
    this.contactService.contact(this.contact)
      .subscribe((message) => {
        this.isLoading = false;
        this.angualertService.info(message);
        this.contact = new Contact();
      }, error => {
        this.isLoading = false;
        this.angualertService.error(error.message)
      });
  }

  // restartTutorial() {
  //   this.featureService.restart();
  // }
}
