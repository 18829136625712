import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';

import { environment } from '../../environments/environment';
import Logger from '../utils/logger';

/**
 * Based on source from Vladimir Budilov @ https://github.com/awslabs/aws-cognito-angular2-quickstart
 */
export interface CognitoCallback {
  cognitoCallback(error: string, result: any): void;
}
export interface Callback {
  callback(): void;
  callbackWithParam(result: any): void;
}
export interface CognitoData {
  cognitoUser: CognitoUser;
  accessToken?: string;
  idToken?: string;
  isSessionValid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  public static region = environment.region;
  public static identityPoolId = environment.identityPoolId;
  public static userPoolId = environment.userPoolId;
  public static appClientId = environment.appClientId;

  public static poolData: any = {
    UserPoolId: CognitoService.userPoolId,
    ClientId: CognitoService.appClientId
  };

  public cognitoCreds: AWS.CognitoIdentityCredentials;

  getUserPool() {
    if (environment.cognitoIDPEndpoint) {
      CognitoService.poolData.endpoint = environment.cognitoIDPEndpoint;
    }
    return new CognitoUserPool(CognitoService.poolData);
  }

  getCurrentUser(username?: string): CognitoUser {
    let user = this.getUserPool().getCurrentUser();
    if (user != null) return user;

    if (user == null && username != null && username !== '') {
      return new CognitoUser({
        Username: username,
        Pool: this.getUserPool()
      });
    }

    return null;
  }

  public getCognitoUser(username?: string): Observable<CognitoData> {
    return new Observable<CognitoData>(subscriber => {
      let cognitoUser = this.getUserPool().getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            return subscriber.error(err);
          }

          let data = {
            cognitoUser,
            isSessionValid: session.isValid()
          } as CognitoData;
          if (session.isValid()) {
            data.accessToken = session.getAccessToken().getJwtToken();
            data.idToken = session.getIdToken().getJwtToken();
          }

          return subscriber.next(data);
        })
      } else {
        if (cognitoUser == null && username != null && username !== '') {

          cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.getUserPool()
          });

          return subscriber.next({
            cognitoUser,
            isSessionValid: false
          });
        } else {
          return subscriber.error('No user logged in.')
        }
      }
    });
  }

  /**
   * AWS Stores Credentials in many ways, and with TypeScript this means that getting the base credentials we authenticated with from the AWS globals gets really murky, having to get around both class extension and unions. Therefore, we're going to give developers direct access to the raw, unadulterated CognitoIdentityCredentials object at all times.
   * @param creds
   */
  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds() {
    return this.cognitoCreds;
  }

  /**
   * This method takes in a raw jwtToken and uses the global AWS config options to build a CognitoIdentityCredentials object and store it for us. It also returns the object to the caller to avoid unnecessary calls to setCognitoCreds.
   * @param idTokenJwt
   */
  buildCognitoCreds(idTokenJwt: string) {
    let url = 'cognito-idp.' + CognitoService.region.toLowerCase() + '.amazonaws.com/' + CognitoService.userPoolId;
    if (environment.cognitoIDPEndpoint) {
      url = environment.cognitoIDPEndpoint + '/' + CognitoService.userPoolId;
    }
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoService.identityPoolId, /* required */
      Logins: logins
    };
    let serviceConfigs: awsservice.ServiceConfigurationOptions = {};
    if (environment.cognitoIdentityEndpoint) {
      serviceConfigs.endpoint = environment.cognitoIdentityEndpoint;
    }
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }

  getCognitoIdentity(): string {
    return this.cognitoCreds.identityId;
  }

  /**
   * Gets the current user's access token
   * @param callback The callback, containing either an error message, or no error message and the access token
   */
  getAccessToken(callback: CognitoCallback): void {
    this.checkSession(callback, (session: any) => {
      return session.getAccessToken().getJwtToken();
    });
  }

	/**
	 * Gets the current user's id token
	 * @param callback The callback, containing either an error message, or no error message and the id token
	 */
  public getIdToken(callback: CognitoCallback): void {
    this.checkSession(callback, (session: any) => {
      return session.getIdToken().getJwtToken();
    });
  }

	/**
	 * Checks the current user's session. If the session is valid, the successDelegate will be called to determine what to pass back in the CognitoCallback.
	 * @param callback
	 * @param successDelegate Function that needs to return something from the session, like the id token or access token
	 */
  private checkSession(callback: CognitoCallback, successDelegate: any): void {
    if (callback == null) {
      throw ('CognitoService: callback in getIdToken is null...returning');
    }
    let user = this.getCurrentUser();
    if (user != null) {
      user.getSession(function (err, session) {
        if (err) {
          // Can't set the credentials in CognitoService
          callback.cognitoCallback('Sorry, there was a problem checking your user session.', null);
        } else {
          if (session.isValid()) {
            let token = successDelegate(session);
            callback.cognitoCallback(null, token);
          } else {
            callback.cognitoCallback('Sorry, your user session is not valid.', null);
          }
        }
      });
    } else {
      callback.cognitoCallback('Not logged in.', null);
    }
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw ('CognitoService: callback in getRefreshToken is null...returning');
    }
    let user = this.getCurrentUser();
    if (user != null) {
      user.getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  refresh(): void {
    let user = this.getCurrentUser();
    if (user != null) {
      user.getSession(function (err, session) {
        if (err) {
          Logger.log(`CognitoService: Can't set the credentials: ` + err);
        } else {
          if (session.isValid()) {
            Logger.log('CognitoService: refreshed successfully');
          } else {
            Logger.log('CognitoService: refreshed but session is still not valid');
          }
        }
      });
    }
  }
}
