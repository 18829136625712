import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AuthDirective } from '../../admin/auth.directive';
import { UserService } from '../user.service';
import { GoogleDriveService } from '../../utils/google-drive/google-drive.service';
import { AngualertService } from 'angualert';
import { LoginCodes } from '../login-result';
import { User } from '../user';
import Logger from 'src/app/utils/logger';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild(AuthDirective) auth: AuthDirective;
  user: User;
  updateUser: User;
  isEdit: boolean;
  isAuthorized: boolean = false;
  isSettingUp = true;
  private isAuthSub: Subscription;
  private setupStatusSub: Subscription;

  constructor(private userService: UserService,
    private googleDriveService: GoogleDriveService,
    private angualertService: AngualertService) {

    this.updateUser = new User();
  }
  ngOnInit() {
    // Subscribe to any changes in the Google auth
    this.isAuthSub = this.googleDriveService.isAuthorized$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
    });

    // Initial setup Google Drive Service
    this.setupStatusSub = this.googleDriveService.isSettingUp$.subscribe(isSettingUp => {
      this.isSettingUp = isSettingUp;
    }, error => {
      Logger.error(error);
      this.angualertService.error('There was a problem setting up Google Drive. Please refresh the page and try again, or contact Ed if it continues.');
    });
  }
  ngOnDestroy() {
    this.isAuthSub.unsubscribe();
    this.setupStatusSub.unsubscribe();
  }

  handleLoginChange(user: User) {
    this.user = user;
    if (user != null)
      this.updateUser.username = user.username;
  }

  /**
   * Change your password.
   */
  change() {
    if (this.updateUser.newPassword !== this.updateUser.confirmPassword) {
      return this.angualertService.warn('Your passwords need to match.');
    }
    this.userService.updatePassword(this.updateUser)
      .subscribe((result) => {
        if (result.code === LoginCodes.SUCCESS) {
          this.angualertService.success('Updated your password!');
          this.updateUser.password = null;
          this.updateUser.newPassword = null;
          this.updateUser.confirmPassword = null;
        } else {
          this.angualertService.warn('Could not update your password.');
        }
      }, (error) => {
        this.angualertService.error(error.message)
      });
  }

  googleSignIn() {
    this.googleDriveService.signIn();
  }
  googleSignOut() {
    this.googleDriveService.signOut();
  }
  googleRevoke() {
    this.googleDriveService.revokeAccess();
    this.angualertService.success('Revoked access to Google account.');
  }

  /**
   * Update your username.
   * TODO: This is not used for now because the username is fixed in AWS, and I don't want to bother with the preferred_username for now. So I will not allow for changing the username right now.
   */
  // update() {
  //   if (this.updateUser.username === this.user.username) {
  //     return this.alertService.warn('No changes to make to the username!');
  //   }
  //   this.loginService.updateUsername(this.updateUser)
  //     .subscribe((updatedUser) => {
  //       if (updatedUser) {
  //         this.alertService.success('Updated the username!');
  //         this.user.username = this.updateUser.username;
  //       } else {
  //         return this.alertService.warn('Could not update the username.');
  //       }
  //     }, (error) => this.alertService.error(error.message));
  // }
}
