import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';

import { UserService } from '../admin/user.service';
import { ThemeService } from '../admin/theme/theme.service';
import { User } from '../admin/user';
import { Theme } from '../admin/theme/themes';
import { FormControl } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { PicService } from '../pic/pic.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  user: User = null;
  picSearchControl = new FormControl();
  currentTheme: Theme = null;
  appName = environment.appName;
  private themeSub: Subscription;
  private routerSub: Subscription;

  constructor(private router: Router,
    private loginService: UserService,
    private picService: PicService,
    themeService: ThemeService) {
    this.routerSub = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const segements = event.url.split('/');
        if (segements.length === 3 && segements[1] === 'search') {
          // Update the search bar text if it does not have the search query, like if the page is reloaded
          const picSearchValue = this.picSearchControl.value;
          if (picSearchValue == null || picSearchValue === '') {
            const query = segements[2];
            this.picSearchControl.setValue(query);
          }
        }
      }
    });

    this.themeSub = themeService.theme$.subscribe(theme => this.currentTheme = theme);
    themeService.loadTheme();
  }
  ngOnInit() {
    this.picSearchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(picSearchText => {
        if (picSearchText == null || picSearchText === '') {
          return this.router.navigate(['/']);
        }
        this.router.navigate(['/search/' + picSearchText]);
        this.picService.searchPictures(picSearchText).subscribe(pics => { })
      });
  }
  ngOnDestroy() {
    this.themeSub.unsubscribe();
    this.routerSub.unsubscribe();
  }

  handleLoginChange(user: User) {
    this.user = user;
  }

  themeClass() {
    if (this.currentTheme == null) return '';
    return `${this.currentTheme.name} ${this.currentTheme.bright} ${this.currentTheme.text}-text theme-transition`;
  }
  textClass() {
    if (this.currentTheme == null) return '';
    return `${this.currentTheme.text}-text`;
  }

  logout() {
    this.loginService.logout();
  }
}
