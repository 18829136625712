import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as DynamoDB from 'aws-sdk/clients/dynamodb';

import { Theme, DefaultTheme } from './themes';
import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/utils/base.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';
import Logger from 'src/app/utils/logger';
import ApiResponse from 'src/app/utils/api-response';
import { AngualertService } from 'angualert';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends BaseService {
  theme$ = new BehaviorSubject<Theme>(DefaultTheme);

  constructor(http: HttpClient,
    userService: UserService,
    private angualertService: AngualertService) {
    super('ThemeService', http, userService);
  }

  loadTheme() {
    super.get<ApiResponse<Theme>>(environment.apiUrl + '/themes', true)
      .pipe(
        map(response => {
          if (response.statusCode !== 200) {
            Logger.error(`Error in theme load: ${response.message} Error: ${response.error}, Stack: ${response.stack}`);
            return;
          }

          let theme;
          if (response.data != null && response.data.name != null) {
            theme = response.data;
          } else {
            theme = DefaultTheme;
          }
          this.theme$.next(theme)
        }),
        catchError(error => super.handleError(error))
      ).subscribe(
        () => { },
        error => {
          this.theme$.next(DefaultTheme);
        }
      );
  }

  changeTheme(theme: Theme) {
    this.theme$.next(theme);

    let params = {
      TableName: environment.themeTable,
      Item: {
        id: environment.themeTableKey,
        name: theme.name,
        text: theme.text,
        bright: theme.bright !== '' ? theme.bright : null
      }
    }

    this.getDocClient().put(params, (err) => {
      if (err) {
        this.angualertService.error('Could not save theme, please try again.')
        return Logger.error(JSON.stringify(err, null, 2));
      }
    });
  }

  private getDocClient(): DynamoDB.DocumentClient {
    return new DynamoDB.DocumentClient();
  }
}
