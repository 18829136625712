import { Component } from '@angular/core';

import { ThemeService } from './theme.service';
import { Theme, ThemeGroup, themeGroups } from './themes';

@Component({
	selector: 'theme',
  templateUrl: './theme.component.html',
  styles: [
    `.theme:hover {opacity: 0.5; background-color:#4177C1!important}`,
    `.theme-container { max-height: 500px; overflow-y: auto; }`
  ]
})
export class ThemeComponent {
  public themeGroups: ThemeGroup[] = themeGroups;
  public standardThemes: Array<ThemeGroup> = [];
  public hardThemes: Array<ThemeGroup> = [];

  constructor(private themeService: ThemeService) {
  }

  public selectTheme(theme: Theme) {
    this.themeService.changeTheme(theme);
  }
}
