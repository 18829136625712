/**
 * A directory for holding files and other directories.
 */
export class Directory {
  name: string;
  directories = new Array<Directory>();
  files = new Array<File>();

  constructor(name?: string, files?: Array<File>) {
    this.name = name;
    this.files = files || [];
  }

	/**
	 * Adds a file to this directory, and gives it a fileId.
	 * @param file
	 */
  addFile(file: File) {
    this.files.push(file);
  }

  addDirectory(directory: Directory) {
    this.directories.push(directory);
  }
}
