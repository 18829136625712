import { Component, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { GoogleDriveService } from './google-drive.service';
import { AngualertService } from 'angualert';
import FileData from '../dragon-drop/file-data';
import Logger from '../logger';

@Component({
  selector: 'google-drive',
  template: `
  <ng-container *ngIf="!isAuthorized">
    <button (click)="googleSignIn()" [disabled]="isSettingUp" class="waves-effect waves-light btn green darken-2">
      <i class="material-icons right">cloud_download</i> Google Sign In
    </button>
  </ng-container>
  <ng-container *ngIf="isAuthorized">
    <button (click)="import()" id="googleBtn" [disabled]="isSettingUp" class="waves-effect btn green darken-2">
      <i class="material-icons left">cloud_download</i> <span id="googleDriveBtn">Google Drive</span>
    </button>
  </ng-container>`
})
export class GoogleDriveComponent implements OnInit, OnDestroy {
  @Output() filesReady: EventEmitter<FileData[]> = new EventEmitter;
  isAuthorized: boolean = false;
  isSettingUp = true;
  private isAuthSub: Subscription;
  private isSettingUpSub: Subscription;

  constructor(private googleDriveService: GoogleDriveService,
    private angualertService: AngualertService) {
  }
  ngOnInit() {
    // Subscribe to any changes in the Google auth
    this.isAuthSub = this.googleDriveService.isAuthorized$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
    });

    // Initial setup Google Drive Service
    this.isSettingUpSub = this.googleDriveService.isSettingUp$.subscribe(isSettingUp => {
      this.isSettingUp = isSettingUp;
    }, error => {
      Logger.error(error);
      this.angualertService.error('There was a problem setting up Google Drive. Please refresh the page and try again, or contact Ed if it continues.');
    });
  }
  ngOnDestroy() {
    this.isAuthSub.unsubscribe();
    this.isSettingUpSub.unsubscribe();
  }

  googleSignIn() {
    this.googleDriveService.signIn();
  }

  import() {
    this.googleDriveService.import()
      .subscribe(images => {
        this.filesReady.emit(images);
      }, error => {
        this.angualertService.error(error);
      });
  }
}
