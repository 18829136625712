import { Component, Input, ViewChild } from '@angular/core';
import { AngualertService } from 'angualert';

import { UserService } from '../../admin/user.service';
import { User } from '../../admin/user';
import { AuthDirective } from '../../admin/auth.directive';
import { PicService } from '../../pic/pic.service';
import { PicData, Tag } from '../../pic/pic-data';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.html'
})
export class ChipsComponent {
  @ViewChild(AuthDirective) auth: AuthDirective;
  @Input() public pic: PicData;
  public tagName: string = '';
  public tag: Tag;
  public user: User;

  constructor(private picService: PicService,
    private angualertService: AngualertService) {}

  public addTag() {
    let tag = new Tag(this.tagName, 'tag');
    this.picService.addTag(tag, this.pic)
      .subscribe(() => {
        this.tagName = null;
      }, error => {
        this.angualertService.error(error)
      });

  }

  public deleteTag(tag: Tag) {
    console.log('deleting tag ' + tag)
  }

  public handleLoginChange(user: User) {
    this.user = user;
  }
}
