import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import * as M from 'materialize-css';

import { uuidv4 } from '../utils/utils';
import { PicService } from '../pic/pic.service';
import { PicData } from './pic-data';
import { User } from '../admin/user';
import { AngualertService } from 'angualert';

@Component({
  selector: 'pic',
  templateUrl: './pic.component.html',
  styleUrls: ['./pic.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('start', style({ display: 'inline-block' })),
      transition('* => *', [
        style({ transform: 'translateX(0) scale(0)' }),
        animate(200, style({ transform: 'translateX(0) scale(1)' }))
      ])
      // transition('void => *', [
      //   style({ transform: 'translateX(0) scale(1.5)' }),
      //   animate(200, style({ transform: 'translateX(0) scale(1)' }))
      // ]),
      // transition('* => void', [
      //   style({ transform: 'translateX(0) scale(1.5)' }),
      //   animate(200, style({ transform: 'translateX(0) scale(0)' }))
      // ]),
      // transition('* => start', [
      //   style({ transform: 'translateY(-60%) scale(0)' }),
      //   animate(200, style({ transform: 'translateX(0) scale(1)' }))
      // ]),
      // transition('* => useGrid', [
      //   style({ transform: 'translateX(0) scale(0)' }),
      //   animate(200, style({ transform: 'translateX(0) scale(1)' }))
      // ])
    ])
  ]
})
export class PicComponent implements AfterViewInit, OnDestroy {
  @Input() pic: PicData;
  @Input() imageClass: string;
  @Output() picDeleted: EventEmitter<PicData> = new EventEmitter();
  picState: string = 'start';
  user: User = null;
  newPicName: string;
  errorImage = '';
  id = uuidv4();
  imgId: string = 'imgId-' + this.id;
  private materialBoxInstance: any;

  constructor(private picService: PicService,
    private angualertService: AngualertService) {
  }
  ngAfterViewInit() {
    this.newPicName = this.pic.name;
    const element = document.querySelector('#' + this.imgId);
    this.materialBoxInstance = M.Materialbox.init(element, {});
  }
  ngOnDestroy() {
    if (this.materialBoxInstance != null)
      this.materialBoxInstance.destroy();
  }

  handleLoginChange(user: User) {
    this.user = user;
  }

  renamePic() {
    if (this.newPicName == null || this.newPicName === '') {
      return this.angualertService.warn('You need to provide a name');
    }
    if (this.newPicName === this.pic.name) return;

    this.picService.renamePicture(this.pic, this.newPicName)
      .subscribe(() => {
        this.pic.fullName = this.pic.fullName.replace(this.pic.name, this.newPicName);
        this.pic.name = this.newPicName;
        this.angualertService.info(`Renamed to '${this.pic.name}'.`);
      }, error => {
        this.angualertService.error(error.message);
      });
  }

  deletePic() {
    if (this.user == null) return;
    this.angualertService.warn(`Are you sure you want to delete the pic '${this.pic.name}'?`, {
      timeout: 10000,
      alertConfirmed: (confirmed) => {
        if (confirmed) {
          this.picService.deletePicture(this.pic)
            .subscribe(() => {
              this.angualertService.info(`Deleted '${this.pic.fullName}'.`);
              this.picDeleted.emit(this.pic);
            }, error => {
              this.angualertService.error(error);
            });
        }
      }
    });
  }

}
