import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreComponent } from './core/core.component';
import { SearchComponent } from './search/search.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './admin/login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { environment } from '../environments/environment';

const routes: Routes = [
  { path: 'admin', component: AdminComponent },
  { path: 'admin/login', component: LoginComponent },
  { path: 'search/:query', component: SearchComponent },
  { path: environment.notFoundUrl, component: NotFoundComponent },
  { path: '**', component: CoreComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
