import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';

import { LoginResult, LoginCodes } from './login-result';
import { User } from './user';
import { environment } from '../../environments/environment';
import { CognitoService } from '../utils/cognito.service';
import Logger from '../utils/logger';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSource = new Subject<User>();
  public user$ = this.userSource.asObservable();
  private resetPasswordKey = 'RESET_PASSWORD_REQUESTED';

  constructor(private cognitoService: CognitoService) {
  }

	/**
	 * Authenticates a user.
	 * @param user
	 */
  public authenticate(user: User, isPasswordUpdate: boolean): Observable<LoginResult> {
    let authenticationDetails = new AuthenticationDetails({
      Username: user.username,
      Password: user.password,
    });
    let cognitoUser = this.cognitoService.getCurrentUser(user.username);

    let self = this;
    return new Observable<LoginResult>(subscriber => {
      let callbacks = {
        onSuccess: result => {
          let creds = self.cognitoService.buildCognitoCreds(result.getIdToken().getJwtToken());

          AWS.config.update({ credentials: creds });

          // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
          // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
          // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
          // security credentials. The identity is then injected directly into the credentials object.
          // If the first SDK call we make wants to use our IdentityID, we have a
          // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
          // very innocuous API call that forces this behavior.
          let clientParams: any = {};
          if (environment.stsEndpoint) {
            clientParams.endpoint = environment.stsEndpoint;
          }
          let sts = new STS(clientParams);
          sts.getCallerIdentity(function () {
            subscriber.next({ code: LoginCodes.SUCCESS });
          });

          this.userSource.next(new User(user.username));
        },
        onFailure: error => {
          if (error.code === LoginCodes.PASSWORD_RESET) {
            subscriber.next({
              code: LoginCodes.PASSWORD_RESET,
              message: error.message
            });
          } else {
            subscriber.error({
              code: LoginCodes.ERROR,
              message: error.message
            });
          }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          if (isPasswordUpdate) {
            cognitoUser.completeNewPasswordChallenge(user.newPassword, requiredAttributes, callbacks);
          } else {
            subscriber.next({
              code: LoginCodes.PASSWORD_UPDATE,
              message: 'You need to update your password.'
            });
          }
        }
      }
      cognitoUser.authenticateUser(authenticationDetails, callbacks);
    });
  }

	/**
	 * Updates a user's password.
	 * @param user
	 * @param callback
	 */
  public updatePassword(user: User): Observable<LoginResult> {
    return this.cognitoService.getCognitoUser()
      .pipe(
        flatMap(data => {
          return new Promise((resolve, reject) => {
            if (data.isSessionValid) {
              let cognitoUser = data.cognitoUser;
              cognitoUser.changePassword(user.password, user.newPassword, (error) => {
                if (error) {
                  if (error.stack.includes(LoginCodes.LIMIT_EXCEEDED)) {
                    return reject({
                      message: error.message,
                      code: LoginCodes.LIMIT_EXCEEDED
                    });
                  } else if (error.stack.includes(LoginCodes.INVALID_PARAMETER)) {
                    return reject({
                      message: error.message,
                      code: LoginCodes.INVALID_PARAMETER
                    });
                  } else if (error.stack.includes(LoginCodes.NOT_AUTHORIZED)) {
                    return reject({
                      message: error.message,
                      code: LoginCodes.NOT_AUTHORIZED
                    });
                  } else if (error.stack.includes(LoginCodes.INVALID_PASSWORD)) {
                    return reject({
                      message: error.message,
                      code: LoginCodes.INVALID_PASSWORD
                    });
                  } else {
                    return reject({
                      code: LoginCodes.ERROR,
                      message: 'Sorry, could not change your password.'
                    });
                  }
                } else {
                  return resolve({ code: LoginCodes.SUCCESS });
                }
              });
            } else {
              return reject({
                code: LoginCodes.ERROR,
                message: 'Your login session is invalid. Please log in again.'
              })
            }
          });
        })
      );
  }

  public forgotPassword(user: User, forceResend: boolean = false): Observable<LoginResult> {
    let cognitoUser = this.cognitoService.getCurrentUser(user.username);

    return new Observable<LoginResult>(subscriber => {
      // Check if the user has already requested a verification code
      let key = this.getAlreadyRequestedKey(user);
      let alreadyRequested = localStorage.getItem(key);
      if (alreadyRequested === 'true' && forceResend === false) {
        return subscriber.next({ code: LoginCodes.SUCCESS });
      }

      cognitoUser.forgotPassword({
        onSuccess: () => {
          localStorage.setItem(key, 'true');
          subscriber.next({
            code: LoginCodes.SUCCESS,
            message: 'An email has been sent with your verification code to reset your password.'
          });
        },
        onFailure: (error) => {
          // TODO Better error handling
          Logger.error(error);
          if (error.stack.includes(LoginCodes.LIMIT_EXCEEDED)) {
            subscriber.error({
              message: error.message,
              code: LoginCodes.LIMIT_EXCEEDED
            });
          } else {
            subscriber.error({
              message: 'There was a problem trying to reset your password, please try again',
              code: LoginCodes.ERROR
            });
          }
        }
      });
    })
  }
  public confirmForgotPassword(user: User): Observable<LoginResult> {
    let cognitoUser = this.cognitoService.getCurrentUser(user.username);

    return new Observable<LoginResult>(subscriber => {
      cognitoUser.confirmPassword(user.verificationCode, user.newPassword, {
        onSuccess: () => {
          // Clear out the already request cache and continue on
          localStorage.removeItem(this.getAlreadyRequestedKey(user));
          subscriber.next({ code: LoginCodes.SUCCESS });
        },
        onFailure: (error) => {
          if (error.stack.includes(LoginCodes.CODE_MISMATCH)) {
            subscriber.error({
              message: error.message,
              code: LoginCodes.CODE_MISMATCH
            });
          } else if (error.stack.includes(LoginCodes.LIMIT_EXCEEDED)) {
            subscriber.error({
              message: error.message,
              code: LoginCodes.LIMIT_EXCEEDED
            });
          } else {
            subscriber.error({
              message: 'There was a problem trying to reset your password, please try again',
              code: LoginCodes.ERROR
            });
          }
        }
      });
    })
  }
  private getAlreadyRequestedKey(user: User): string {
    return `${this.resetPasswordKey}_${user.username}`;
  }

	/**
	 * Complete's the new password challenge for a user.
	 * @param user The user to update
	 * @param callback The callback,
	 */
  public newPasswordChallenge(user: User): Observable<boolean> {
    let authenticationDetails = new AuthenticationDetails({
      Username: user.username,
      Password: user.password
    });
    let cognitoUser = this.cognitoService.getCurrentUser(user.username);

    return new Observable<boolean>(observer => {
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // The api doesn't accept this field back
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(user.newPassword, requiredAttributes, {
            onSuccess: () => {
              observer.next(true);
            },
            onFailure: error => {
              observer.error(error);
            }
          });
        },
        onSuccess: result => {
          observer.next(result.isValid());
        },
        onFailure: error => {
          observer.error(error);
        }
      });
    });
  }

  public getUser(): Observable<User> {
    return this.cognitoService.getCognitoUser()
      .pipe(
        flatMap(data => {
          return new Promise<User>((resolve, reject) => {
            if (data.isSessionValid) {
              return resolve(new User(data.cognitoUser.getUsername()));
            } else {
              reject(null);
            }
          });
        })
      )
  }

  public getIdToken(): Observable<string> {
    return new Observable<string>(observer => {
      this.cognitoService.getIdToken({
        cognitoCallback: (error, token) => {
          if (error) {
            observer.error({
              message: error,
              status: 401
            });
          } else {
            observer.next(token);
          }
        }
      });
    });
  }

  public isLoggedIn(): Observable<boolean> {
    return new Observable(observer => {
      let user = this.cognitoService.getCurrentUser();
      if (user == null) {
        observer.next(false);
        return;
      }
      user.getSession((error, session) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(session.isValid());
        }
      });
    });
  }

  public logout() {
    this.cognitoService.getCurrentUser().signOut();
    this.userSource.next(null);
  }

	/**
	 * Updates a user's preferred username.
	 * TODO: This is not used for now because the username is fixed in AWS, and I don't want to bother with the preferred_username for now.
	 *  So I will not allow for changing the username right now.
	 * @param user
	 */
  // public updateUsername(user: User): Observable<boolean> {
  //   let cognitoUser = this.cognitoService.getCurrentUser();
  //   let attributes: ICognitoUserAttributeData[] = [
  //     {
  //       Name: 'preferred_username',
  //       Value: user.username
  //     }
  //   ];

  //   return new Observable<boolean>(observer => {
  //     cognitoUser.getSession((error, session) => {
  //       if (session.isValid()) {
  //         cognitoUser.updateAttributes(attributes, (error, result) => {
  //           if (error) {
  //             observer.error(error);
  //           } else {
  //             this.userChanged$.emit(user);
  //             observer.next(true);
  //           }
  //         });
  //       } else {
  //         observer.error(`You're not authenticated.`);
  //       }
  //     });
  //   });
  // }

}
